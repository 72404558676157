import {MD3DarkTheme, Provider as PaperProvider} from 'react-native-paper';
import React, {useEffect} from "react";
import {withAuthenticator} from 'aws-amplify-react-native';
import {NavigationWrapper} from "./Navigation";
import {setUserState} from "./auth";


const App = ({authState, authData}) => {
    useEffect(() => setUserState(authState, authData), []);
    return (
        <PaperProvider theme={MD3DarkTheme}>
            <NavigationWrapper/>
        </PaperProvider>
    );
}


const signUpConfig = {
    hideAllDefaults: true,
    signUpFields: [
        {
            label: 'Email',
            key: 'username',
            required: true,
            placeholder: 'Email',
            type: 'email',
            displayOrder: 1,
        },
        {
            label: 'Password',
            key: 'password',
            required: true,
            placeholder: 'Password',
            type: 'password',
            displayOrder: 2,
        },
    ],
}

export default withAuthenticator(App, {
        includeGreetings: false, // don't auto-render a Sign-Out button once logged in
        usernameAttributes: 'email',
        signUpConfig: signUpConfig,
        theme: null,
    }
);
