import {NavigationContainer, DefaultTheme, DarkTheme} from "@react-navigation/native";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {useColorScheme, Linking, View} from "react-native";
import {useFonts, Roboto_400Regular, Roboto_700Bold} from '@expo-google-fonts/roboto';
import {RobotoCondensed_400Regular, RobotoCondensed_700Bold} from "@expo-google-fonts/roboto-condensed";
import AdminScreen from "./Admin";
import BrowseScreen from "./Browse";
import GameScreen from "./Game";
import HomeScreen from "./Home";
import {startDataStoreSync} from "./data";

import {Amplify, AuthModeStrategyType} from "aws-amplify";
import awsExports from '../aws-exports';
import {useGameState} from "./state";
import {refreshAuthenticatedUser, signOut} from "./auth";
import {MyButton, MyText} from "./ui";
import {userIsFriend} from "./logic";

Amplify.configure({
    ...awsExports,
    Analytics: {disabled: true},
    DataStore: {
        // per https://docs.amplify.aws/lib/datastore/setup-auth-rules/q/platform/react-native/#configure-multiple-authorization-types
        authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
    }
});

// Amplify.Logger.LOG_LEVEL = 'DEBUG';

const Stack = createNativeStackNavigator();

const DontKnowYou = () => {
    return (
        <View style={{flexGrow: 1, alignItems: 'center', margin: 50}}>
            <MyText style={{color: 'black'}}>Sorry, this email address isn't authorized.</MyText>
            <MyText style={{color: 'black'}}>Please contact Justin for access.</MyText>
            <View style={{flexDirection: 'row', margin: 30, alignItems: 'stretch'}}>
                <MyButton onPress={() => refreshAuthenticatedUser().then()} label={"Refresh"}/>
                <MyButton onPress={signOut} icon="logout" label="Sign Out" style={{width: 150, marginLeft: 30}}/>
            </View>
        </View>
    );
}

export const NavigationWrapper = () => {
    let [fontsLoaded] = useFonts({
        'roboto': Roboto_400Regular, 'roboto-condensed': RobotoCondensed_400Regular,
        'roboto-bold': Roboto_700Bold, 'roboto-condensed-bold': RobotoCondensed_700Bold,
    });

    const scheme = useColorScheme();
    const gameState = useGameState();
    const user = gameState?.user;

    if (!fontsLoaded) return null;
    if (!user || !user.email.get()) return null;
    if (!(user.groups.get() && userIsFriend())) {
        return (<DontKnowYou/>);
    }
    startDataStoreSync();

    return (
        <NavigationContainer theme={scheme === 'dark' ? DarkTheme : DefaultTheme}>
            <Stack.Navigator initialRouteName="Home" screenOptions={{headerShown: false}}>
                <Stack.Screen name="Home" component={HomeScreen}/>
                <Stack.Screen name="Browse" component={BrowseScreen}/>
                <Stack.Screen name="Play" component={GameScreen}/>
                <Stack.Screen name="Admin" component={AdminScreen}/>
            </Stack.Navigator>
        </NavigationContainer>
    )
}