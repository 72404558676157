import {hookstate, none, State, useHookstate} from '@hookstate/core';
import {PuzzLog, PuzzSummary} from "../models";
import {cloneViaJson} from "./util";


// patterned after:
// https://github.com/avkonst/hookstate/blob/master/docs/demos/todolist/src/components/TasksState.ts
// https://github.com/avkonst/hookstate/blob/master/docs/demos/todolist/src/components/TasksViewer.tsx


export interface MetaData {
    pid: string,
    date_added: string,
    spec: string,
    tags: Array<string>,
    blue: number,
    white: number,
    min_turns: number,
    terms: Array<string>,
    is_unlisted: boolean,
}

export interface Card {
    word: string;
    is_target: boolean;
    is_revealed: boolean;
    turns_clued: Array<number>;
    is_bonus: boolean;
}

export const cardChar = (card: Card, game_over: boolean) => {
    return card.is_revealed ?
        (card.is_target ? ' ✓' : card.is_bonus ? ' +' : ' ✘') :
        (game_over && card.is_target) ? '☐️' : '';
}

export interface Clue {
    term: string;
    count: number;
    intended: Array<number>; // indices of cards targeted
    score: number;
}

export interface Turn {
    clue: Clue;
    guesses: Array<number>;
    bonuses: Array<number>;
}

export interface Feedback {
    badClue: Array<boolean>;
    comments: string,
}

export interface Status {
    can_request_clue: boolean;
    pending_clue_options: Array<Clue>;
    can_guess: boolean;
    show_clue_feedback_ui: boolean;
    show_targeted_cards: Array<number>;
    game_over: "false" | "won" | "lost-turns" | "lost-mistakes";
    num_clued_but_unrevealed: number,
    tot_blue_guessed: number;
    tot_mistakes: number;
    review_stars: number;
    feedback: Feedback;
}

// these must be kept in sync with the Enums in board.py
type BonusReveals =
    'NONE'
    | 'ONE_FOR_MISTAKE_FREE_TURN'
    | 'TWO_FOR_MISTAKE_FREE_TURN'
    | 'MATCH_MISTAKE_FREE_TURN'
    | 'MATCH_MISTAKE_FREE_TURN_AFTER_FIRST';
type CountChoice = "OPTIMAL" | "CHOICE" | "INFINITY";

export const SPEC_SECTIONS = {
    // these correspond to the SoloSpec definitions in puzz.py
    'normal1c': {title: 'Normal', shortTitle: '', handicap: 1},
    'cautious1c': {title: 'Cautious', shortTitle: '(Cautious)', handicap: 0},
    'aggressive1c': {title: 'Aggressive', shortTitle: '(Aggressive)', handicap: 2},
    'choice1c': {title: "Player's Choice", shortTitle: '(Choice)', handicap: 1},
};

export interface GameSpec {
    pid: string;
    max_turns: number;
    max_mistakes: number;
    par: number;
    bonus_reveals: BonusReveals;
    count_choice: CountChoice;
    header_style: 'boxes' | 'badges';
}

export interface PuzzState {
    spec: GameSpec,
    status: Status;
    cards: Array<Card>;
    bixs: Array<number>;
    wixs: Array<number>;
    turns: Array<Turn>;
}

export interface UserState {
    uid: string;
    authState?: string;
    email: string;
    nickname: string;
    groups: Array<string>;
    token: string;
}

export interface UserConfig {
    scoreboardFilter: ScoreboardFilter;
    scoreboardSorter: ScoreboardSorter;
    selectedBoardSize: 'mini' | 'full';
}

export type ScoreboardFilter = 'played' | 'unplayed';
export type ScoreboardSorter = 'rating' | 'win%';

export interface GameState {
    query_in_progress: "ls" | "new" | "loadPuzz" | "clue" | "cython" | "none";
    user: UserState;  // user state from Cognito
    puzz: PuzzState; // state of the current game in progress
    config: UserConfig; // cross-game per-user parameters

    lastDataSync: number,
    summaries: Array<PuzzSummary>;
    myLogs: { [index: string]: PuzzLog };
    adminAllLogs: Array<PuzzLog>;
}

const initial_global_state: GameState = {
    query_in_progress: "none",
    user: {
        uid: 'unknown',
        email: '',
        nickname: '',
        groups: [],
        token: '',
    },
    puzz: null,
    config: {
        selectedBoardSize: 'mini',
        scoreboardFilter: 'played',
        scoreboardSorter: 'rating',
    },
    lastDataSync: 0,
    summaries: [],
    myLogs: {},
    adminAllLogs: [],
};

// I don't really get the rules of hooks generally, nor the rules of hookstate in particular.
// But it seems true that within a TSX component, you should access the global state via useGameState(),
// whereas within TS functions, you should access the global_state hookstate directly.
export const global_state = hookstate<GameState>(cloneViaJson(initial_global_state));

console.log('initial puzz', global_state.puzz.get());

export function resetGameState() {
    global_state.set(cloneViaJson(initial_global_state));
    global_state.puzz.set(none);
}

export function useGameState(): State<GameState> {
    // This function exposes the state directly.
    const hookstate = useHookstate(global_state);
    return hookstate.promised ? null : hookstate;
}


