import {View, StyleSheet, ImageBackground, ScrollView} from "react-native";
import {SafeAreaView} from "react-native-safe-area-context";
import {StatusBar} from "expo-status-bar";
import {Appbar, Button, Dialog, Divider, IconButton, Menu, Portal} from 'react-native-paper';
import React from "react";
import {useHookstate} from "@hookstate/core";
import {GAME_MAX_WIDTH, MyButton, MyDropDown, MyText, useGameDimensions} from "./ui";
import {useGameState} from "./state";
import {refreshAuthenticatedUser, signOut} from "./auth";
import {GAME_BACKGROUND_COLOR} from "./ui";
import {autoClue, userIsAdmin} from "./logic";
import {getNextPid, loadPuzz} from "./data";


const GAME_NAME: string = 'Meaningfle';
const GAME_VERSION: string = 'v0.10';

const HelpModal = ({isVisible}) => {
    const gameState = useGameState();
    const user = gameState?.user;
    return (
        <Portal>
            <Dialog
                visible={isVisible.get()}
                onDismiss={() => isVisible.set(false)}
                style={{
                    backgroundColor: GAME_BACKGROUND_COLOR, padding: 0, alignSelf: 'center',
                    maxHeight: '100%', maxWidth: GAME_MAX_WIDTH
                }}
            >
                {/*<Dialog.Icon icon="help"/>*/}
                <Dialog.ScrollArea style={{margin: 0}}>
                    <ScrollView contentContainerStyle={{padding: 0}}>
                        <Dialog.Title>{`Welcome${user.nickname.get() ? ", " + user.nickname.get() : ""}!`}</Dialog.Title>
                        <Dialog.Content>
                            <MyText numLines={50}>
                                Thanks for helping to test and improve my Codenames-inspired word game!
                            </MyText>
                            <View style={{height: 20}}/>
                            <MyText numLines={50}>
                                Rules: you'll see a board with either 12 cards (the "warm-ups") or
                                21 cards (full-sized). Your objective is to guess the hidden target cards — 5 of 12
                                on the warm-ups, or 9 of 21 on the full-sized boards —
                                using as few clues as possible. But guess carefully: 3 mistakes and
                                you lose!
                            </MyText>
                            <View style={{height: 20}}/>
                            <MyText numLines={50}>
                                You can explore different AI clue styles (cautious, moderate, aggressive), or
                                just play in "Variety" mode. After playing a board, please rate it from 1–5 stars,
                                and mark any particularly bad clues with a thumbs-down. Thanks again!
                            </MyText>

                        </Dialog.Content>
                    </ScrollView>
                </Dialog.ScrollArea>
                <Dialog.Actions>
                    <Button dark={true} mode="outlined" onPress={() => isVisible.set(false)}>Let's go!</Button>
                </Dialog.Actions>
            </Dialog>
        </Portal>
    )
}


const GameHeader = ({navigation, onHelp}) => {
    const gameState = useGameState();
    const user = gameState?.user;
    const accountMenuVisible = useHookstate(false);
    return (
        <Appbar.Header mode="center-aligned">
            <Appbar.Content style={{marginLeft: 'auto'}} title={null}/>
            <Appbar.Content
                style={{marginLeft: 0, position: 'absolute', left: 0, right: 0, zIndex: -1}} // center absolutely.
                title={GAME_NAME}/>
            {userIsAdmin() &&
                <Appbar.Action icon="shield-lock"
                               onPress={() => {
                                   accountMenuVisible.set(false);
                                   navigation.navigate("Admin");
                               }}/>}
            <Menu visible={accountMenuVisible.get()}
                  onDismiss={() => accountMenuVisible.set(false)}
                  anchor={<IconButton style={{margin: 0}} icon="account"
                                      onPress={() => accountMenuVisible.set(true)}/>}>
                <MyText style={{padding: 10}} bold={true}>
                    Account&nbsp;
                </MyText>
                <Divider/>
                <MyText narrow style={{padding: 10}}>
                    {user?.email?.get()}
                </MyText>
                {user?.groups?.length >= 1 &&
                    <MyText narrow style={{padding: 10}}>
                        ({user?.groups?.get()?.join(", ")})
                    </MyText>}
                <Menu.Item disabled={false} leadingIcon="refresh" onPress={refreshAuthenticatedUser} title="Refresh"/>
                <Menu.Item disabled={false} leadingIcon="logout" onPress={signOut} title="Log out"/>
            </Menu>
            <Appbar.Action icon="help-circle-outline" onPress={onHelp}
                           style={{margin: 0}}
            />
        </Appbar.Header>
    );
}


const HomeScreen = ({navigation}) => {
    const gameState = useGameState();
    const {usableHeight, usableWidth} = useGameDimensions();
    const helpVisible = useHookstate(false);
    const selectedBoardSize = gameState?.config?.selectedBoardSize;
    const puzz = gameState?.puzz;
    const pidInProgress = puzz?.status?.game_over?.get() === 'false' && puzz?.spec?.pid?.get();
    const nextPid = getNextPid();
    console.log('HomeScreen: lastDataSync=', gameState?.lastDataSync?.get()?.toString(),
        ' thisPid', pidInProgress, ' nextPid=', nextPid);
    return (
        <SafeAreaView style={styles.container}>
            <ImageBackground source={require('../assets/mj6.jpg')}
                             style={{
                                 margin: 0,
                                 padding: 0,
                                 width: '100%',
                                 flexGrow: 1,
                             }}>
                <View style={{
                    flexDirection: 'column', flexGrow: 1, margin: 'auto',
                    width: usableWidth, maxHeight: usableHeight
                }}>
                    <HelpModal isVisible={helpVisible}/>
                    <StatusBar hidden={true} animated={true} style="auto"/>
                    <GameHeader navigation={navigation} onHelp={() => helpVisible.set(true)}/>

                    <View style={{  // column of menu buttons
                        flexGrow: 1.0,
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <View style={{
                            flexGrow: 0.4,
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <MyDropDown
                                disabled={false}
                                label="Board size"
                                stateVal={selectedBoardSize}
                                list={[
                                    {label: 'Mini', value: 'mini'},
                                    {label: 'Full', value: 'full'},
                                ]}
                                inputStyle={{width: 170}}
                                containerStyle={{margin: 5}}
                            />
                            {pidInProgress && pidInProgress === nextPid ?
                                <MyButton
                                    fontSize={20}
                                    style={styles.bigButtons}
                                    onPress={() => {
                                        autoClue();
                                        navigation.navigate('Play');
                                    }}
                                    icon={'play'}
                                    label={"Resume"}/>
                                :
                                <MyButton
                                    disabled={!nextPid}
                                    fontSize={20}
                                    style={styles.bigButtons}
                                    loading={!nextPid || gameState?.query_in_progress.get() !== "none"}
                                    onPress={() => {
                                        loadPuzz(nextPid).then(() => navigation.navigate('Play'))
                                    }}
                                    icon={'play'}
                                    label={pidInProgress ? 'Play new' : 'Play'}/>
                            }
                        </View>
                        <View style={{
                            flexGrow: 0.6,
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <MyButton
                                disabled={false}
                                style={styles.buttons}
                                onPress={() => {
                                    navigation.navigate('Browse');
                                }}
                                icon='view-list'
                                label='Scoreboard'
                            />
                        </View>
                    </View>
                </View>
            </ImageBackground>
        </SafeAreaView>
    );
}

export default HomeScreen;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: GAME_BACKGROUND_COLOR,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    buttons: {
        width: 180,
        alignItems: 'flex-start',
        margin: 15,
        padding: 3,
    },
    bigButtons: {
        width: 180,
        height: 70,
        alignItems: 'flex-start',
        justifyContent: 'center',
        margin: 5,
        padding: 0,
    }
});
