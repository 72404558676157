import {Auth, DataStore} from 'aws-amplify';
import {startDataStoreSync} from "./data";
import {global_state, resetGameState} from "./state";

// Documentation on (legacy) Amplify React Native authentication:
// https://github.com/aws-amplify/amplify-ui/tree/legacy/legacy/aws-amplify-react-native
// https://docs.amplify.aws/lib/auth/getting-started/q/platform/react-native/

// import {useAuthenticator} from "@aws-amplify/ui-react-native";

export async function signOut() {
    try {
        console.log('signing out...');
        await Auth.signOut();
        console.log('clearing...');
        await DataStore.clear();
        resetGameState();
    } catch (error) {
        console.log('error signing out: ', error);
    }
}

export function setUserState(authState: string, authData: any) {
    const user = global_state?.user;
    if (!user)
        return;
    if (authState)
        user.authState.set(authState);
    // console.log('authData', authData);
    user.uid.set(authData.username);
    user.email.set(authData?.attributes?.email);
    user.nickname.set(authData?.attributes?.nickname);
    user.groups.set(authData?.signInUserSession?.accessToken?.payload["cognito:groups"]);
    user.token.set(authData?.signInUserSession?.idToken?.jwtToken);
    // console.debug('auth', user.authState.get(), user.email.get(), user.groups.get());
    startDataStoreSync();
}

export async function refreshAuthenticatedUser() {
    await DataStore.clear(); // unload subscriptions
    const authData = await Auth.currentAuthenticatedUser({bypassCache: true});
    console.log('user', authData);
    setUserState(null, authData);
}
