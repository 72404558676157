import {SafeAreaView, ScrollView, StyleSheet, TouchableOpacity, View} from "react-native";
import {GAME_BACKGROUND_COLOR, KELLY, MyColumn, MyRow, MyStars, MyText} from "./ui";
import React, {useEffect} from "react";
import {useHookstate} from "@hookstate/core";
import {Appbar, DataTable, Divider, IconButton, Menu} from 'react-native-paper';
import {StatusBar} from "expo-status-bar";
import {adminUpdatePuzzLogs, importSummaries, startDataStoreSync} from "./data";
import {DataStore} from "aws-amplify";
import {findSumm, userIsAdmin} from "./logic";
import {queryCython} from "./logic";
import {global_state, useGameState} from "./state";
import * as Clipboard from 'expo-clipboard';
import {FeedbackCell} from "./Browse";
import {shortDateTimeString} from "./util";
import {MaterialCommunityIcons} from "@expo/vector-icons";

const AdminHeader = ({navigation, adminState}) => {
    const dbMenuVisible = useHookstate(false);
    const outputBuffer = adminState.outputBuffer;
    const show = adminState.show;
    return (
        <Appbar.Header mode="center-aligned" style={{flexGrow: 0, height: 50, marginTop: 0}}>

            <Appbar.Action icon="arrow-left" onPress={navigation.goBack}/>
            <Appbar.Action icon='content-copy'
                           onPress={() => {
                               show.set('output');
                               Clipboard.setStringAsync(outputBuffer.get().join('\n'))
                           }}/>

            <Appbar.Action icon="eye" color="white"
                           onPress={() => {
                               show.set('output');
                               queryCython().then(r => outputBuffer.set(r.guesses));
                           }}/>

            <Appbar.Action icon="cloud-braces" color="white"
                           onPress={() => {
                               show.set('output');
                               outputBuffer.set([JSON.stringify(global_state.adminAllLogs.get())])
                           }}/>

            <Appbar.Content mode="large" color="white" title="Admin"/>

            <Appbar.Action icon="account-group" color="white"
                           onPress={() => show.set('logs')}/>

            <Menu visible={dbMenuVisible.get()}
                  onDismiss={() => dbMenuVisible.set(false)}
                  anchor={<IconButton icon="database" onPress={() => dbMenuVisible.set(true)}/>}>
                <MyText style={{padding: 10}} bold={true}>
                    Database
                </MyText>
                <Divider/>
                <Menu.Item disabled={false}
                           leadingIcon="refresh"
                           onPress={async () => {
                               await DataStore.clear();
                               startDataStoreSync();
                           }}
                           title="Refresh DataStore"/>
                <Menu.Item leadingIcon="alert"
                           title="Update PuzzLogs (dangerous!)"
                           onPress={() => {
                               dbMenuVisible.set(false);
                               adminUpdatePuzzLogs().then(() => null);
                           }}
                />
                <Menu.Item leadingIcon="database-import"
                           title="Rebuild PuzzSummary table"
                           onPress={() => {
                               dbMenuVisible.set(false);
                               importSummaries().then(() => null);
                           }}
                />
            </Menu>
        </Appbar.Header>
    );
}

const ShowLogs = () => {
    const allPuzzLogs = useGameState().adminAllLogs.get().slice();
    allPuzzLogs.sort((a, b) => b.date_played.localeCompare(a.date_played));
    const s = {flex: 1};
    return (
        <MyColumn>
            <MyRow style={{justifyContent: 'space-evenly'}}>
                <MyText bold style={s}>Date</MyText>
                <MyText bold style={s}>Email</MyText>
                <MyText bold style={s}>Pid</MyText>
                <MyText bold style={s}># clues</MyText>
                <MyText bold style={s}>Review</MyText>
                <MyText bold style={s}>Feedback</MyText>
            </MyRow>
            {allPuzzLogs.map((plog, index) =>
                (<MyRow key={index} style={{paddingVertical: 5, borderWidth: 0.5}}>
                    <MyText style={s}>{shortDateTimeString(new Date(plog.date_played))}</MyText>
                    <MyText style={s}>{plog.email.match(/(.*)@/)[1]}</MyText>
                    <TouchableOpacity onPress={() => {
                        Clipboard.setStringAsync(plog.of_pid)
                    }}>
                        <View style={{flexDirection: 'row', justifyContent: 'flex-start'}}>
                            <MyText style={s} narrow>{plog.of_pid.substring(0, 5)}</MyText>
                            <MaterialCommunityIcons name='content-copy' color='white'/>
                        </View>
                    </TouchableOpacity>
                    <MyText style={s}>{plog.nclues_to_win}</MyText>
                    <View style={s}>
                        <MyStars rating={plog.review} starSize={17} color={KELLY.vivid_orange}/>
                    </View>
                    <MyText style={s}>
                        <FeedbackCell puzzLog={plog} summ={findSumm(plog.of_pid)}
                        />
                    </MyText>
                </MyRow>))}
        </MyColumn>
    );
}

const AdminScreen = ({navigation}) => {
    useEffect(() => {
        if (!userIsAdmin()) {
            console.error('!admin, bailing');
            navigation.goBack();
            return;
        }
    }, []);
    const adminState = useHookstate(
        {
            outputBuffer: ["Admin output goes here!"],
            show: 'output',
        }
    );
    return (
        <SafeAreaView style={styles.container}>
            <StatusBar hidden={true} animated={true} style="auto"/>
            <AdminHeader navigation={navigation} adminState={adminState}/>
            <ScrollView style={{
                flexGrow: 1,
            }}>
                {adminState.show.get() === 'output' ?
                    adminState.outputBuffer.get().map((line, index) =>
                        <MyText key={index} narrow>
                            {index}. {line}
                        </MyText>)
                    :
                    <ShowLogs/>
                }
            </ScrollView>
        </SafeAreaView>
    );
}

export default AdminScreen;

const styles = StyleSheet.create({
    container: {
        flexDirection: "column",
        backgroundColor: GAME_BACKGROUND_COLOR,
        width: '100%',
        height: '100%',
    },
});
