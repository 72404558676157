import {Card, global_state, MetaData, SPEC_SECTIONS, Turn} from "./state";
import axios from "axios";
import {HEAVY_DUTCH, KELLY} from "./ui";
import axiosRetry from "axios-retry";
import {Platform} from "react-native";
import equal from "fast-deep-equal/es6";
import {PuzzLog, PuzzSummary} from "../models";


const AUTO_CLUE = true;

export const CLUE_COLORS = [KELLY.vivid_green, KELLY.strong_blue, KELLY.vivid_red,
    KELLY.vivid_yellow, KELLY.strong_purple, KELLY.vivid_orange, KELLY.strong_purplish_pink,
    HEAVY_DUTCH.green, HEAVY_DUTCH.blue, KELLY.strong_reddish_brown,
    HEAVY_DUTCH.yellow, HEAVY_DUTCH.purple, HEAVY_DUTCH.orange, HEAVY_DUTCH.magenta,
    KELLY.vivid_yellowish_green, HEAVY_DUTCH.teal, HEAVY_DUTCH.red,
    KELLY.vivid_orange_yellow, KELLY.strong_violet, KELLY.reddish_orange, KELLY.strong_yellowish_pink];


const PIZZA_API_VERSION = 8;
const PIZZA_API_URL = __DEV__ && Platform.OS !== 'android' ? 'http://127.0.0.1:3000/pizza' :
    'https://fwdt3levnh.execute-api.us-east-1.amazonaws.com/Prod/pizza';


interface LsQuery {
    v: number,
    cmd: 'ls'
}

interface NewQuery {
    v: number,
    cmd: 'new',
    pid: string,
}

interface ClueQuery {
    v: number,
    cmd: 'clue',
    pid: string,
    spec_id?: string,
    prev_turns: Array<Turn>,
}

type PizzaQuery = LsQuery | NewQuery | ClueQuery | { cmd: 'cython' };


// Exponential back-off retry delay between requests
axiosRetry(axios, {
    retries: 3,
    retryDelay: (retryCount) => 1000 * 2 ** retryCount, // exponential backoff 2, 4, 8 seconds
});


const pizzaQuery = async (query: PizzaQuery): Promise<any> => {
    const state = global_state;
    console.log('pizzaQuery');
    if (state?.query_in_progress.get() !== "none") {
        console.log('query_in_progress', state?.query_in_progress.get());
        await new Promise(r => setTimeout(r, 1000)); // wait 1 second before returning
        return undefined;
    }
    state.query_in_progress.set(query.cmd);
    try {
        const response = await axios.post(PIZZA_API_URL, query, {headers: {'Authorization': `Bearer ${state?.user?.token?.get()}`}});
        state.query_in_progress.set("none");
        // console.log('response.data.result', response.data.result);
        return response.data.result;
    } catch (error) {
        console.error('query error', query, error.toJSON());
        state.query_in_progress.set("none");
        return undefined;
    }
}

export const lsPuzzIds = async (): Promise<Array<MetaData>> => {
    // admin-only query
    if (!userIsAdmin()) {
        console.error('unauthorized to importSummaries');
        // TODO: protect on back-end (issue #31)
        return;
    }
    const result = await pizzaQuery({v: PIZZA_API_VERSION, cmd: 'ls'}) as any;
    console.log('lsPuzzIds', result?.metadatas.length);
    return result ? result.metadatas : null;
}


export const getMyLog = (pid): PuzzLog => {
    // returns the current player's PuzzLog for the given pid, or undefined if unplayed
    return global_state?.myLogs[pid]?.get();
}
export const findSumm = (pid): PuzzSummary => {
    return global_state?.summaries?.get().find(summ => summ.pid === pid) as PuzzSummary;
}
export const queryNewPuzz = async (pid: string) => {
    const puzz = global_state?.puzz;
    if (!puzz) return;
    puzz?.turns?.set([]);
    puzz?.cards?.set([]);
    puzz?.status?.game_over?.set('false');
    const result = await pizzaQuery({v: PIZZA_API_VERSION, cmd: 'new', pid: pid});
    if (!result)
        return result;
    const meta: MetaData = result.meta;
    const summMeta = findSumm(pid)?.metadata as unknown as MetaData;
    if (!equal(meta, summMeta)) {
        console.warn('metadata mismatch: db', summMeta, 'server', meta);
    }
    const cards: Array<Card> = meta.terms.map((word, index) => {
        return {
            word: word,
            is_target: !result.wixs.includes(index),
            is_revealed: false,
            turns_clued: [],
            is_bonus: false
        } as Card;
    });
    puzz.set({
        spec: {
            pid: meta.pid,
            max_turns: Math.min(CLUE_COLORS.length, result.bixs.length),
            max_mistakes: 3,
            par: meta.min_turns ? meta.min_turns + (SPEC_SECTIONS[meta.spec]?.handicap ?? 1) : undefined,
            bonus_reveals: result.bonus_reveals,
            count_choice: result.count_choice,
            header_style: 'badges',
        },
        cards: cards,
        bixs: result.bixs,
        wixs: result.wixs,
        turns: [],
        status: {
            can_request_clue: true,
            pending_clue_options: [],
            can_guess: false,
            game_over: "false",
            show_clue_feedback_ui: false,
            show_targeted_cards: [],
            num_clued_but_unrevealed: 0,
            tot_blue_guessed: 0,
            tot_mistakes: 0,
            review_stars: 0,
            feedback: {badClue: [], comments: undefined},
        }
    });
    return puzz;
}

export const queryNextClue = async () => {
    const puzz = global_state?.puzz;
    const status = puzz?.status;
    const reveals = puzz?.spec?.bonus_reveals?.get();
    if (!status.can_request_clue.get()) return; // cannot request clue now.
    if (status.can_guess.get()) { // ended guessing early
        status.can_guess.set(false);
        revealBonusEnvelopes(reveals === 'ONE_FOR_MISTAKE_FREE_TURN' ? 1 :
            reveals === 'TWO_FOR_MISTAKE_FREE_TURN' ? 2 :
                reveals === 'MATCH_MISTAKE_FREE_TURN' ? puzz.turns[puzz.turns.length - 1].guesses.length :
                    reveals === 'MATCH_MISTAKE_FREE_TURN_AFTER_FIRST' ? puzz.turns[puzz.turns.length - 1].guesses.length - 1 :
                        0);
    }
    const result = await pizzaQuery({
        v: PIZZA_API_VERSION,
        cmd: 'clue',
        pid: puzz.spec.pid.get(),
        // spec_id: 'easy1',
        prev_turns: puzz.turns.get({noproxy: true}) as Turn[]
    });
    if (!result)
        return result;
    status.can_request_clue.set(false);
    puzz.spec.bonus_reveals.set(result.bonus_reveals); // # bonus reveals to be granted for this turn if mistake-free
    puzz.spec.count_choice.set(result.count_choice);
    status.pending_clue_options.set(result.clues);
    if (status.pending_clue_options.length === 1) {
        chooseClueOption(0);
    }
}

export const queryCython = async () => {
    return await pizzaQuery({cmd: 'cython'})
}

export function revealBonusEnvelopes(count: number) {
    const puzz = global_state.puzz;
    // console.log('revealBonusEnvelopes', count)
    let num_revealed = 0;
    for (let i = 0; i < puzz.wixs.length && num_revealed < count; i++) {
        const wix = puzz.wixs[i].get();
        const card = puzz.cards[wix];
        if (!card.is_revealed.get() && !card.is_target.get()) {
            num_revealed++;
            // console.log('reveal: ', card.word.get());
            card.is_revealed.set(true);
            card.is_bonus.set(true);
            // add term to this turn's guess list
            puzz.turns[puzz.turns.length - 1].bonuses.merge([wix]);
        }
    }
}

const updateNumCluesRemaining = () => {
    const puzz = global_state?.puzz;
    const cards = puzz?.cards;
    if (!cards) return;
    let num = 0;
    for (let i = 0; i < cards.length; i++) {
        if (cards[i].turns_clued.length > 0 && !cards[i].is_revealed.get()) {
            num++;
        }
    }
    puzz.status.num_clued_but_unrevealed.set(num);
}

// Select a clue, by index, from the global state var pending_clue_options[].
export const chooseClueOption = (clueIndex: number) => {
    const puzz = global_state?.puzz;
    const clue = puzz?.status?.pending_clue_options[clueIndex].get({noproxy: true});
    // console.log('chose clue:', clue, 'with intended', clue.intended);
    clue.intended.map((ix) => {
        // console.debug('intending', ix);
        // console.debug(ix, 'cards', puzz.cards);
        // console.debug(ix, 'card', puzz.cards[ix]);
        // console.debug(ix, 'turns_clued', puzz.cards[ix].turns_clued);
        puzz.cards[ix].turns_clued.merge([puzz.turns.length]);
    })
    puzz.turns.merge([{
        clue: clue,
        guesses: [],
        bonuses: []
    }]);
    updateNumCluesRemaining();
    puzz.status.can_guess.set(true);
    puzz.status.pending_clue_options.set([]);
}


export function autoClue() {
    const status = global_state?.puzz?.status;
    if (AUTO_CLUE && global_state?.query_in_progress.get() === 'none' && !status.can_guess.get() && status.can_request_clue.get()) {
        // console.log('queryNextClue from autoClue')
        queryNextClue();
    }
}

export const handleGuess = (index) => {
    const puzz = global_state.puzz;
    const card = puzz.cards[index];
    const status = puzz.status;
    const bonus_reveals = puzz?.spec.bonus_reveals.get();

    // console.log('handleGuess', index, card.get({stealth: true}));

    // reveal card
    card.is_revealed.set(true);
    const num_clues_remaining_was = status.num_clued_but_unrevealed.get();
    updateNumCluesRemaining();

    // add term to this turn's guess list
    puzz.turns[puzz.turns.length - 1].guesses.merge([index]);

    // update status flags
    if (puzz.turns.length < puzz.spec.max_turns.get())
        status.can_request_clue.set(true);
    if (card.is_target.get()) {
        status.tot_blue_guessed.set(tbg => (tbg + 1));
        if (status.tot_blue_guessed.get() === puzz.bixs.length) {
            // console.log('You won!')
            status.game_over.set('won');
            status.can_guess.set(false);
            status.can_request_clue.set(false);
        } else {
            if (status.num_clued_but_unrevealed.get() === 0) {
                status.can_guess.set(false); // disable guessing when #revealed == total count of all clues so far
                revealBonusEnvelopes(bonus_reveals === 'ONE_FOR_MISTAKE_FREE_TURN' ? 1 :
                    bonus_reveals === 'TWO_FOR_MISTAKE_FREE_TURN' ? 2 :
                        bonus_reveals === 'MATCH_MISTAKE_FREE_TURN' ? puzz.turns[puzz.turns.length - 1].guesses.length :
                            bonus_reveals === 'MATCH_MISTAKE_FREE_TURN_AFTER_FIRST' ? Math.max(0, puzz.turns[puzz.turns.length - 1].guesses.length - 1) :
                                0);
            }
            const luckyGuess = status.num_clued_but_unrevealed.get() === num_clues_remaining_was;
            if (luckyGuess) {
                status.can_guess.set(false); // disable guessing after luckily guessing a target.
            }
        }
    } else {
        status.tot_mistakes.set((val) => {
            return val + 1;
        });
        status.can_guess.set(false); // disable guessing after guessing a non-target.
    }
    if (puzz.turns.length >= puzz.spec.max_turns.get() && !status.can_guess.get()) {
        status.game_over.set('lost-turns');
        status.can_request_clue.set(false);
        status.can_guess.set(false);
    } else if (status.tot_mistakes.get() >= puzz.spec.max_mistakes.get()) {
        status.game_over.set('lost-mistakes');
        status.can_request_clue.set(false);
        status.can_guess.set(false);
    }
    autoClue();
}

export function userIsAdmin() {
    const user = global_state?.user;
    return user?.groups?.get()?.find(group => group === "Admin");
}

export function userIsFriend() {
    const user = global_state?.user;
    return user?.groups?.get()?.find(group => group === "Friend");
}

