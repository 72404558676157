import {
    Platform, SafeAreaView, StyleProp, StyleSheet,
    Text, TouchableOpacity, useWindowDimensions,
    View, ViewStyle
} from "react-native";
import {RFValue} from "react-native-responsive-fontsize";
import {LinearGradient} from "expo-linear-gradient";
import {Badge, Button} from "react-native-paper";
import React from "react";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import StarRating, {StarRatingDisplay} from "react-native-star-rating-widget";
import {useHookstate} from "@hookstate/core";
import DropDown from "./DropDown";
import Popover from "react-native-popover-view";


export const KELLY = { // http://jsfiddle.net/k8NC2/1/
    dark_olive_green: '#232C16',
    deep_yellowish_brown: '#593315',
    grayish_yellow: '#CEA262',
    medium_gray: '#817066',
    reddish_orange: '#F13A13',
    strong_blue: '#0063DC', // was #00538A
    strong_purple: '#803E75',
    strong_purplish_pink: '#F6768E',
    strong_purplish_red: '#B32851',
    strong_reddish_brown: '#7F180D',
    strong_violet: '#53377A',
    strong_yellowish_pink: '#FF7A5C',
    very_light_blue: '#A6BDD7',
    vivid_green: '#007D34',
    vivid_greenish_yellow: '#F4C800',
    vivid_orange: '#FF6800',
    vivid_orange_yellow: '#FF8E00',
    vivid_red: '#C10020',
    vivid_yellow: '#FFB300',
    vivid_yellowish_green: '#93AA00',
};

// https://www.heavy.ai/blog/12-color-palettes-for-telling-better-stories-with-your-data
export const HEAVY_DUTCH = {
    red: "#e60049", blue: "#0bb4ff", green: "#50e991",
    yellow: "#e6d800", purple: "#9b19f5", orange: "#ffa300",
    magenta: "#dc0ab4", light_blue: "#b3d4ff", teal: "#00bfa0"
};

export const GAME_BACKGROUND_COLOR = '#1A152C';
export const GAME_BACKGROUND_TRANSPARENT = GAME_BACKGROUND_COLOR + 'CC'; // rgba
export const GAME_LIGHT_BACKGROUND_COLOR = '#4F2F6C';
export const TRANSPARENT_COLOR = '#0000';
export const TOUCHABLE_BORDER_COLOR = KELLY.grayish_yellow;
export const REVIEW_YELLOW = '#fdd835';
const USER_SELECT_NONE = {userSelect: 'none'}; // list this style separately to avoid spurious PyCharm error message

export const GAME_MAX_WIDTH = 750;
export const GAME_MAX_HEIGHT = 900;
export const GAME_CLUEBOX_TARGET_WIDTH = 400;

const lightenColor = (hexColor, magnitude) => {
    hexColor = hexColor.replace(`#`, ``);
    if (hexColor.length === 6) {
        const decimalColor = parseInt(hexColor, 16);
        let r = (decimalColor >> 16) + magnitude;
        r > 255 && (r = 255);
        r < 0 && (r = 0);
        let g = (decimalColor & 0x0000ff) + magnitude;
        g > 255 && (g = 255);
        g < 0 && (g = 0);
        let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
        b > 255 && (b = 255);
        b < 0 && (b = 0);
        return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
    } else {
        return hexColor;
    }
};

export const useGameDimensions = () => {
    const {height, width} = useWindowDimensions();
    const usableWidth = Math.min(width, GAME_MAX_WIDTH);
    const usableHeight = Math.min(height, GAME_MAX_HEIGHT);
    return {usableHeight, usableWidth};
}


export const WordChip = ({disabled, colors, innerHeight, innerWidth, borderWidth, borderColor, onPress, children}) => {

    function monochromeGradient(color: string) {
        const darker = lightenColor(color, -15);
        return [color, darker, color];
    }

    const colorGradient = colors.length > 1 ? [...colors, ...colors] : monochromeGradient(colors[0]);
    return (
        <View style={{
            height: innerHeight + 2 * borderWidth,
            width: innerWidth + 2 * borderWidth,
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <TouchableOpacity disabled={disabled} onPress={onPress}>
                <LinearGradient
                    start={[0, 0]}
                    end={[1, 1]}
                    colors={colorGradient}
                    style={{
                        height: innerHeight,
                        width: innerWidth,
                        borderWidth: borderWidth,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderColor: borderColor,
                        opacity: disabled ? 0.8 : 1,
                        borderRadius: 8,
                    }}>
                    {children}
                </LinearGradient>
            </TouchableOpacity>
        </View>
    );
}

export const MyBadge = ({
                            score,
                            size,
                            backgroundColor = 'lightgray',
                            color = 'black',
                            square = false,
                            style = null,
                            backgroundIcon = null,
                        }) => {
    return (
        <>
            <Badge visible={true} size={size}
                   style={[{
                       color: color,
                       fontFamily: 'roboto-bold',
                       backgroundColor: backgroundIcon ? TRANSPARENT_COLOR : backgroundColor,
                       alignSelf: 'center',
                   }, USER_SELECT_NONE, square ? [{borderRadius: 0}] : [], style]}>
                {score}
            </Badge>
            {backgroundIcon &&
                <MaterialCommunityIcons name={backgroundIcon} size={size + 8}
                                        style={{
                                            position: 'absolute',
                                            color: backgroundColor,
                                            zIndex: -1,
                                            left: 0,
                                            right: 0
                                        }}/>}
        </>
    )
}

export const BadgeField = ({
                               leftLabel = null,
                               badgeValue = null,
                               rightLabel = null,
                               labelTextSize = 14,
                               labelNarrow = true,
                               badgeColor = 'black',
                               badgeBackgroundColor = 'lightgray',
                               badgeSize = 30,
                               badgeBorderWidth = 0,
                               style = null,
                           }) => {
    return (
        <View style={[{
            flexDirection: 'row',
            borderWidth: 0.5,
            borderRadius: 10,
            paddingHorizontal: 10,
            paddingVertical: 0,
            borderColor: 'gray',
            alignItems: 'center',
            marginHorizontal: 0,
        }, style]}>
            {leftLabel && <MyText size={labelTextSize} narrow={labelNarrow}>{leftLabel}</MyText>}
            {badgeValue !== undefined && badgeValue != null &&
                <MyBadge score={badgeValue} size={badgeSize} color={badgeColor}
                         backgroundColor={badgeBackgroundColor}
                         style={{borderWidth: badgeBorderWidth, borderColor: badgeColor}}/>}
            {rightLabel && <MyText size={labelTextSize} narrow={labelNarrow}>{rightLabel}</MyText>}
        </View>
    )
}

export const CheckboxIcon = ({checked, color, size = 20, xbox = false}) => {
    return (<MaterialCommunityIcons
        name={checked ? (xbox ? 'close-box' : 'checkbox-marked') : 'checkbox-blank-outline'}
        size={size} color={color}/>)

}

export const CheckboxesField = ({
                                    leftLabel = null,
                                    rightLabel = null,
                                    numBoxes,
                                    numChecked,
                                    color,
                                    size,
                                    xbox = false,
                                    style = null,
                                }) => {
    return (
        <View style={{flexDirection: 'column'}}>
            <View style={[{
                flexDirection: 'row',
                borderWidth: 0.5,
                borderRadius: 10,
                paddingVertical: 6,
                paddingHorizontal: 6,
                borderColor: 'gray',
                alignItems: 'center'
            }, style]}>
                {leftLabel && <MyText size={14} narrow={true} style={{color: 'white'}}>{leftLabel}&nbsp;&nbsp;</MyText>}
                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                    {Array(numBoxes).fill(0).map((cell, ix) =>
                        <CheckboxIcon key={ix} checked={ix < numChecked} color={color} size={size} xbox={xbox}/>)}
                </View>
                {rightLabel &&
                    <MyText size={14} narrow={true} style={{color: 'white'}}>&nbsp;&nbsp;{rightLabel}</MyText>}
            </View>
        </View>
    )
}

export const MyButton = ({
                             disabled = false, loading = false, icon = null, style = null,
                             narrow = false, bold = false, fontSize = 16,
                             textColor = 'white', buttonColor = 'black', onPress, label
                         }) => {
    const family: string = 'roboto' + (narrow ? '-condensed' : '') + (bold ? '-bold' : '');
    return (
        <Button disabled={disabled}
                dark={Platform.OS === 'ios'} // this is the only way to make 'contained-tonal' mode legible on web & iOS.
                mode='outlined'
                loading={loading}
                compact={icon === null}
                icon={icon}
                style={style}
                contentStyle={{justifyContent: 'flex-start',}}
                labelStyle={{fontFamily: family, fontSize: fontSize,}}
                buttonColor={buttonColor}
                textColor={textColor}
                onPress={onPress}>
            {label}
        </Button>);
}

export const MyDropDown = ({
                               disabled = false, label, stateVal, list,
                               inputStyle = null, outlineStyle = null, containerStyle = null
                           }) => {
    const isDropped = useHookstate(false);
    return (
        <View style={containerStyle}>
            <DropDown
                label={label}
                mode={'outlined'}
                visible={isDropped.get()}
                showDropDown={() => !disabled && isDropped.set(true)}
                onDismiss={() => isDropped.set(false)}
                value={stateVal.get()}
                setValue={(val) => stateVal.set(val)}
                list={list}
                dropDownContainerMaxHeight={350}
                dropDownItemTextStyle={{color: 'white', width: 350}}
                inputProps={{disabled: disabled, style: inputStyle, outlineStyle: outlineStyle}}
            />
        </View>)
}


export const MyStars = ({
                            changeable = false,
                            rating = 0,
                            onChange = (number) => null,
                            starSize,
                            color = REVIEW_YELLOW,
                        }) => {
    const starStyle = {marginHorizontal: -0.5};
    return (
        <View style={{alignItems: 'center', justifyContent: 'flex-end'}}>
            {/*<MyText size={8} narrow>{rating}</MyText>*/}
            {changeable ?
                <StarRating rating={rating} enableHalfStar={false} onChange={onChange}
                            starSize={starSize} starStyle={starStyle}/> :
                <StarRatingDisplay rating={rating} color={color}
                                   starSize={starSize} starStyle={starStyle}/>
            }
        </View>
    )
}

export const MyTooltip = ({title, children}) => {
    return (
        <Popover from={children}>
            <View>
                <Text style={{color: 'black', maxWidth: 300, padding: 15}}>{title}</Text>
            </View>
        </Popover>
    )
}

export const MyRow = ({style = null, children}: { style?: StyleProp<ViewStyle>, children: any }) => {
    return (
        <View style={[{flexDirection: 'row'}, style]}>
            {children}
        </View>
    )
}

export const MyColumn = ({style = null, children}: { style?: StyleProp<ViewStyle>, children: any }) => {
    return (
        <View style={[{flexDirection: 'column'}, style]}>
            {children}
        </View>
    )
}

export const MyText = ({narrow = false, bold = false, size = 17, style = {}, numLines = 1, children}) => {
    const family: string = 'roboto' + (narrow ? '-condensed' : '') + (bold ? '-bold' : '');
    // const {height, width} = useWindowDimensions(); // causes re-render when screen dimensions change
    return (
        <Text
            adjustsFontSizeToFit={true}
            numberOfLines={Platform.OS === 'web' ? null : numLines}
            allowFontScaling={true}
            minimumFontScale={0.05}
            style={[{
                color: 'white',
                fontFamily: family,
                fontSize: Math.min(size * 1.1, RFValue(size, 650)),
            }, USER_SELECT_NONE, style]}
        >
            {children}
        </Text>
    );
}

export default MyText;

