import {useGameState} from "./state";
import {ScrollView, View} from "react-native";
import {
    BadgeField,
    GAME_MAX_WIDTH,
    KELLY,
    MyButton,
    MyColumn,
    MyDropDown,
    MyRow,
    MyStars,
    MyText,
    REVIEW_YELLOW
} from "./ui";
import {Divider, IconButton, MD3LightTheme, Menu, Modal, Portal, TextInput} from "react-native-paper";
import React, {useEffect, useRef} from "react";
import {State, useHookstate} from "@hookstate/core";
import {getNextPid, loadPuzz, logGame} from "./data";
import {userIsAdmin} from "./logic";
import {MaterialCommunityIcons} from "@expo/vector-icons";

const MAX_COMMENT_LENGTH = 1000;

const RatingWidget = () => {
    const state = useGameState();
    const puzz = state?.puzz;
    return (
        <MyStars
            changeable={true}
            rating={puzz.status.review_stars.get() || 0}
            onChange={(val) => {
                puzz.status.review_stars.set(val);
                logGame().then();
            }}
            starSize={26}
        />
    )
}
const OutcomeLine = () => {
    const state = useGameState();
    const puzz = state?.puzz;
    const spec = puzz?.spec;
    const turns = puzz?.turns;
    const status = puzz?.status;
    const feedback = status?.feedback;
    const game_over = status?.game_over?.get();
    useEffect(() => {
        // Set up bad clue array, but only after rendering MarkBadsLine to avoid simultaneous updates in GameClueBox
        if (feedback.badClue.length != turns.length) {
            feedback.badClue.set(new Array(turns.length).fill(false));
        }
        puzz?.status.show_clue_feedback_ui.set(true);
    }, []);

    function exhortation(took: number, par: number) {
        if (!par) return '';
        if (took === par) return '. Nice!';
        if (took === par - 1) return '. Sweet!';
        if (took <= par - 2) return '. Amazing!';
        return '!';
    }

    return (
        <View
            style={{
                marginBottom: 10,
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'space-evenly'
            }}>
            {game_over === 'won' ?
                <BadgeField leftLabel='You won in '
                            rightLabel={(puzz?.turns.length == 1 ? ' clue' : ' clues') +
                                exhortation(puzz.turns.length, puzz.spec.par.get())}
                            labelTextSize={20}
                            badgeValue={puzz?.turns.length}
                            style={{borderWidth: 0}}/>
                :
                <MyText narrow bold={false} size={20} style={{textAlign: 'center'}}>
                    Alas, you lost.
                </MyText>
            }
        </View>
    );
}

const RatingLine = () => {
    const state = useGameState();
    const puzz = state?.puzz;
    return (
        <MyRow style={{alignItems: 'flex-end', justifyContent: 'center', marginBottom: 2}}>
            <MyText narrow bold={false} size={18}
                    style={{textAlign: 'center', color: KELLY.very_light_blue}}>
                Fun board?
            </MyText>
            <View style={{alignItems: 'center', paddingHorizontal: 10, paddingVertical: 0}}>
                <RatingWidget/>
            </View>
            <MyText narrow bold={false} size={18}
                    style={{textAlign: 'center', color: KELLY.very_light_blue}}>
                &nbsp;Bad clues?&nbsp;
            </MyText>
            <MaterialCommunityIcons name='arrow-up-right' size={18} color={KELLY.very_light_blue}
                                    style={{alignSelf: 'flex-start'}}/>
        </MyRow>
    );
}

const CommentModal = ({showCommentModal}) => {
    const feedback = useGameState().puzz?.status?.feedback;
    return (
        <Portal>
            <Modal visible={showCommentModal.get()}
                   onDismiss={() => {
                       showCommentModal.set(false);
                       logGame().then();
                   }}
                   style={{maxWidth: GAME_MAX_WIDTH, margin: 'auto'}}
                   contentContainerStyle={{
                       backgroundColor: 'ghostwhite', padding: 15, paddingBottom: 0, margin: 10, borderRadius: 10
                   }}
            >
                <TextInput
                    mode='outlined'
                    multiline={true}
                    label="Your comments on this board"
                    value={feedback.comments.get()}
                    onChangeText={text => feedback.comments.set(text.substring(0, MAX_COMMENT_LENGTH))}
                    style={{backgroundColor: 'ghostwhite', height: 200}}
                    outlineStyle={{borderColor: REVIEW_YELLOW}}
                    theme={MD3LightTheme}
                    autoFocus={true}
                />
                <IconButton style={{alignSelf: 'flex-end', margin: 0}}
                            icon='content-save' iconColor='black' size={30}
                            onPress={() => {
                                showCommentModal.set(false);
                                logGame().then();
                            }}/>
            </Modal>
        </Portal>
    );
}


export const MovingAlongLine = ({navigation}) => {
    const showCommentModal = useHookstate(false);
    const state = useGameState();
    const status = state?.puzz?.status;
    const feedback = status?.feedback;
    const nextPid = getNextPid();
    return (
        <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            marginTop: 8,
            marginBottom: 5,
        }}>
            {/*<IconButton icon={'share'} iconColor={'white'} animated={true} disabled={true}/>*/}
            {/*{!userIsAdmin() ? null :*/}
            {/*    <MyDropDown*/}
            {/*        disabled={false}*/}
            {/*        label="Replay game"*/}
            {/*        stateVal={replayStyle}*/}
            {/*        list={[*/}
            {/*            {label: 'Cautious', value: 'cautious'},*/}
            {/*            {label: 'Moderate', value: 'normal'},*/}
            {/*            {label: 'Aggressive', value: 'aggressive'},*/}
            {/*            {label: "Choice", value: 'choice'}*/}
            {/*        ]}*/}
            {/*        inputStyle={{height: 40, width: 135}}*/}
            {/*        outlineStyle={{borderRadius: 50}}*/}
            {/*        containerStyle={{margin: 5}}*/}
            {/*    />}*/}
            <MyRow style={{alignItems: 'center'}}>
                <MyText narrow bold={false} size={18} style={{textAlign: 'center', color: KELLY.very_light_blue}}>
                    Comments?
                </MyText>
                <IconButton icon={feedback.comments.get() ? 'comment-text' : 'comment-outline'}
                            iconColor={REVIEW_YELLOW} animated={true}
                            onPress={() => showCommentModal.set(true)}
                            style={{margin: 0}}
                />
                <CommentModal showCommentModal={showCommentModal}/>
            </MyRow>
            <MyButton
                disabled={!nextPid}
                onPress={() => {
                    loadPuzz(nextPid).then(() => null)
                }}
                icon={'play'}
                label={'Next game'}/>
        </View>
    )
}

export const GameOver = ({navigation}) => {
    // const scrollRef = useRef(null)
    const state = useGameState();
    const puzz = state?.puzz;
    const game_over = puzz?.status?.game_over?.get();
    const ratingSubmitted = puzz?.status?.review_stars.get() && puzz?.status?.review_stars.get() >= 0;
    const commentSubmitted = puzz?.status?.feedback?.comments?.get() != undefined;
    if (game_over && game_over !== 'false') {
        return (
            <MyColumn style={{marginBottom: 12}}>
                <OutcomeLine/>
                <RatingLine/>
                {(puzz.status.review_stars.get() && puzz.status.review_stars.get() >= 0) ?
                    <MovingAlongLine navigation={navigation}/>
                    : null}
                {/*</ScrollView>*/}
            </MyColumn>
        );
    } else {
        return null;
    }
}
