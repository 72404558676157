import {PuzzLog, PuzzSummary} from "../models";
import {DataStore, Predicates} from "aws-amplify";
import {Clue, Feedback, global_state, Turn} from "./state";
import {autoClue, chooseClueOption, getMyLog, handleGuess, lsPuzzIds, queryNewPuzz, userIsAdmin} from "./logic";
import equal from "fast-deep-equal/es6";


export const getNextPid = () => {
    if (!global_state.lastDataSync.get() || global_state.summaries.length == 0) {
        return null; // don't return anything until we're synced
    }
    const selectedBoardSize = global_state.config.selectedBoardSize.get();
    const myLogs = global_state.myLogs.get();
    const experience = Object.keys(myLogs).length;
    const summIsShowable =
        selectedBoardSize === 'mini' ?
            (summ) => !summ.is_unlisted && !myLogs[summ.pid] && summ.tags.includes("warmup") :
            selectedBoardSize === 'full' ?
                (summ) => !summ.is_unlisted && !myLogs[summ.pid] && !summ.tags.includes("warmup") && (
                    (experience < 10) ? summ.spec.startsWith("normal") :
                        (experience < 20) ? !summ.spec.startsWith("choice") :
                            true)
                : (summ) => false;
    const nextSumm = global_state.summaries.get().find(summIsShowable);
    console.log('experience=', experience, 'picked', nextSumm);
    return nextSumm?.pid;
}


export function summary_statistics(summ: PuzzSummary) {
    const othersLost = summ.nclues_to_win_histo.length >= 1 ? summ.nclues_to_win_histo[0] : 0;
    const othersWinPct = Math.round(100 * (summ.nplayed - othersLost) / Math.max(1, summ.nplayed));
    const othersWon = summ.nplayed - othersLost;
    let othersTotClues = 0;
    for (let nclues = 1; nclues < summ.nclues_to_win_histo.length; nclues++) {
        othersTotClues += nclues * summ.nclues_to_win_histo[nclues];
    }
    const othersAvgClues = othersWon > 0 ? (othersTotClues / othersWon).toFixed(1) : -1;
    return {othersWon, othersLost, othersWinPct, othersAvgClues};
}

function reviewGoodness(avg, n) {
    // favor reviews that average above 3.5 (with minimum 4 reviewers)
    return n >= 4 ? Math.max(3.5, avg) : 3.5;
}

function summariesComparator(a: PuzzSummary, b: PuzzSummary) {
    return (b.tags.indexOf('featured') - a.tags.indexOf('featured')) ||
        reviewGoodness(b.review_avg, b.nreviews) - reviewGoodness(a.review_avg, a.nreviews) ||
        a.pid.slice(2).localeCompare(b.pid.slice(2)); // compare the pid strings, ignoring the prefix
}

export const startDataStoreSync = () => {
    try {
        DataStore.observeQuery(
            PuzzSummary,
            Predicates.ALL, // was s => s.is_unlisted.eq(false),
            // {sort: s => s.pid(SortDirection.ASCENDING)}
        ).subscribe(snapshot => {
            const {items, isSynced} = snapshot;
            items.sort(summariesComparator);
            if (equal(items, global_state.summaries.get())) {
                console.log('Summaries observer no change');
            } else {
                global_state.summaries.set(items);
                global_state.lastDataSync.set(Number(new Date()));
                console.log(`[Snapshot] summaries count: ${items.length}, isSynced: ${isSynced}`);
            }
        })

        const user = global_state?.user;
        const user_id = user.uid.get();

        DataStore.observeQuery(PuzzLog, pl => pl.user_id.eq(user_id))
            .subscribe(snapshot => {
                const {items, isSynced} = snapshot;
                const logMap = {};
                items.forEach(puzzLog => logMap[puzzLog.of_pid] = puzzLog);
                if (equal(logMap, global_state.myLogs.get())) {
                    console.log('puzzLogs observer no change');
                } else {
                    global_state.myLogs.set(logMap);
                    global_state.lastDataSync.set(Number(new Date()));
                    console.log(`[Snapshot] myLogs count: ${items.length}, isSynced: ${isSynced}`);
                }
            });

        if (userIsAdmin()) {
            DataStore.observeQuery(PuzzLog, Predicates.ALL)
                .subscribe(snapshot => {
                    const {items, isSynced} = snapshot;
                    if (equal(items, global_state.adminAllLogs.get())) {
                        console.log('adminAllLogs observer no change');
                    } else {
                        global_state.adminAllLogs.set(items);
                        global_state.lastDataSync.set(Number(new Date()));
                        console.log(`[Snapshot] adminAllLogs count: ${items.length}, isSynced: ${isSynced}`);
                    }
                });
        }
    } catch (error) {
        console.error('Error starting Datastore observers', error);
    }
};

export const adminGetAllLogsForPid = (pid) => {
    // returns all players' PuzzLogs for the given pid, or undefined if unplayed
    return global_state?.adminAllLogs?.get().filter(log => log.of_pid === pid);
}


export const importSummaries = async () => {
    // Admin action. Snarfs all public puzzle metadata via Python ls query.
    // Then, queries the PuzzLog table to compute and save the summary stats (e.g. # turn histograms) for each puzz.
    // This should really be done server-side, e.g. as follows:
    // https://docs.amplify.aws/lib/graphqlapi/graphql-from-nodejs/q/platform/react-native/
    if (!userIsAdmin()) {
        console.error('unauthorized to importSummaries');
        return;
    }
    console.log('importSummaries:');
    const metadatas = await lsPuzzIds();
    console.log('Loaded fresh metas:', metadatas.length);
    const allLogs = global_state.adminAllLogs.get();
    console.log('Using the observed', allLogs.length, 'puzzlogs');
    console.log('Nuking all prior summaries:', global_state.summaries.length);
    await DataStore.delete(PuzzSummary, Predicates.ALL);

    for (const meta of metadatas) {
        let nplayed = 0;
        let nclues_to_win_histo = new Array(30).fill(0);
        let nmistakes_histo = new Array(10).fill(0);
        let nreviews = 0;
        let sum_reviews = 0;

        for (const log of allLogs.filter(l => l.of_pid === meta.pid)) {
            // collect statistics from all plays of this board
            nplayed++;
            nclues_to_win_histo[log.nclues_to_win]++;  // note 0 means loss
            nmistakes_histo[log.nmistakes]++;
            if (log.review > 0) {
                nreviews++;
                sum_reviews += log.review;
            }
        }

        const newSum = new PuzzSummary({
            pid: meta.pid,
            date_added: meta.date_added,
            is_unlisted: meta.is_unlisted,
            spec: meta.spec,
            tags: meta.tags,
            nplayed: nplayed,
            nclues_to_win_histo: nclues_to_win_histo,
            nmistakes_histo: nmistakes_histo,
            nreviews: nreviews,
            review_avg: nreviews > 0 ? sum_reviews / nreviews : -1.0,
            metadata: JSON.stringify(meta),
        });

        console.log('newSum[', meta.pid, ']: n=', newSum.nplayed, 'rev=', newSum.review_avg, newSum);
        DataStore.save(newSum).then(() => null);
    }
}

export const loadPuzz = async (pid: string, andAutoClue = true) => {
    const puzz = await queryNewPuzz(pid);
    if (!puzz.get()) {
        throw new Error('Failed to load puzzle; check connectivity?');
    }
    const myLog = getMyLog(pid);
    if (myLog) {
        // play out the saved game, turn by turn
        // console.log('loadPuzz Replaying', myLog.turns);
        global_state?.query_in_progress.set("loadPuzz"); // inhibit auto-cluing as we replay game
        for (const turn of myLog.turns as unknown as Turn[]) {
            puzz.status.pending_clue_options.set([turn.clue as Clue]);
            chooseClueOption(0);
            for (const guess of turn.guesses) {
                handleGuess(guess);
            }
            for (const bonus of turn.bonuses) {
                const card = puzz.cards[bonus];
                card.is_revealed.set(true);
                card.is_bonus.set(true);
            }
        }
        try {
            const savedFeedback = myLog.feedback as unknown as Feedback;
            puzz.status.feedback.set({
                // make a new copy of the feedback data, so we can modify it in the GameOver UI.
                badClue: savedFeedback.badClue.slice(),
                comments: savedFeedback.comments
            } as Feedback);
            console.log('Restored feedback: ', puzz.status.feedback.get());
            puzz.status.review_stars.set(myLog.review);
            console.log('Restored stars: ', puzz.status.review_stars.get());
        } catch (error) {
            console.error("Couldn't restore previous feedback:", error);
        }
        global_state?.query_in_progress.set("none");
    }
    if (andAutoClue) {
        autoClue();
    }
}


export const logGame = async () => {
    const user = global_state?.user;
    const puzz = global_state?.puzz;
    const pid = puzz?.spec?.pid.get();
    const status = puzz?.status;
    const user_id = user.uid.get();
    const email = user.email.get();
    const myPuzzLog = getMyLog(pid);
    if (myPuzzLog) {
        console.info('Updating review & feedback fields only on PuzzLog');
        const updatedLog = PuzzLog.copyOf(myPuzzLog, (updated) => {
            updated.email = email;
            updated.review = status?.review_stars?.get();
            updated.feedback = JSON.stringify(status?.feedback?.get());
        });
        return await DataStore.save(updatedLog);
    } else {
        console.log('Adding new PuzzLog for', pid);
        const summs = await DataStore.query(PuzzSummary, c => c.pid.eq(pid));
        if (!summs) {
            console.error('Failed to find a PuzzSummary for the new log entry');
            return;
        }
        if (summs.length !== 1) {
            console.error('Expected to find exactly one PuzzSummary for the new log entry; found', summs.length);
        }
        const log = new PuzzLog({
            of_pid: pid,
            user_id: user_id,
            email: email,
            date_played: new Date().toISOString(), // FIXME should be done server-side with a resolver. Or not done at all.
            spec_played: summs[0].spec,
            turns: JSON.stringify(puzz?.turns?.get()),
            nclues_to_win: status?.game_over.get() === "won" ? puzz?.turns.length : 0,
            nmistakes: status?.tot_mistakes.get(),
            review: status?.review_stars?.get(),
            feedback: JSON.stringify(status?.feedback?.get()),
        });
        return await DataStore.save(log);
    }
}

export const adminUpdatePuzzLogs = async () => {
    const allLogs = global_state?.adminAllLogs.get();
    for (const log of allLogs) {
        const summ = global_state.summaries.get().find(s => s.pid === log.of_pid);
        console.info(`Updating PuzzLog ${log.of_pid},${log.email} to record spec_played=${summ.spec}`);
        const updatedLog = PuzzLog.copyOf(log, (updated) => {
            updated.spec_played = summ.spec;
        });
        await DataStore.save(updatedLog);
    }
}