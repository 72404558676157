export function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}


export function shortDateTimeString(date: Date) {
    return `${1 + date.getMonth()}/${date.getDate()} ${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
}

// Works for objects containing objects, arrays, strings, booleans, and numbers. Not so much for dates, functions, etc.
export function cloneViaJson<T>(item: T): T {
    return JSON.parse(JSON.stringify(item));
}