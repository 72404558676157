import {MetaData, SPEC_SECTIONS, useGameState} from "./state";
import {summary_statistics} from "./data";
import {LazyPuzzSummary} from "../models";
import {DataTable, Dialog, IconButton, Portal} from "react-native-paper";
import {GAME_BACKGROUND_COLOR, GAME_MAX_WIDTH, MyStars, MyText} from "./ui";
import {View} from "react-native";
import React from "react";
import {findSumm} from "./logic";


export const InfoModal = ({isVisible}) => {
    const gameState = useGameState();
    // const user = gameState?.user;
    const spec = gameState?.puzz?.spec;
    const summ = findSumm(spec?.pid?.get());
    const {othersWon, othersLost, othersWinPct, othersAvgClues} = summary_statistics(summ as LazyPuzzSummary);
    const meta = summ.metadata as unknown as MetaData;
    return (
        <Portal>
            <Dialog
                visible={isVisible.get()}
                onDismiss={() => isVisible.set(false)}
                style={{
                    flexGrow: 1,
                    backgroundColor: GAME_BACKGROUND_COLOR, padding: 0, margin: 0,
                    maxWidth: GAME_MAX_WIDTH, alignSelf: 'stretch'
                }}
            >
                <View // title line
                    style={{
                        flexDirection: 'row',
                        paddingLeft: 30,
                        paddingTop: 0,
                        marginTop: 10,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                    <MyText size={24}>Board info</MyText>
                    <IconButton icon="close-box-outline" onPress={() => isVisible.set(false)}/>
                </View>
                <Dialog.Content>
                    <DataTable>
                        <DataTable.Row>
                            <DataTable.Cell>Words:</DataTable.Cell>
                            <DataTable.Cell>{meta.blue} ✓ &nbsp; {meta.white} ✘</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Clue style:</DataTable.Cell>
                            <DataTable.Cell>{SPEC_SECTIONS[meta.spec].title || meta.spec}</DataTable.Cell>
                        </DataTable.Row>
                        {summ.review_avg && summ.review_avg >= 0 ?
                            <DataTable.Row>
                                <DataTable.Cell>Avg. rating:</DataTable.Cell>
                                <DataTable.Cell style={{alignItems: 'center'}}>
                                    {summ.review_avg.toFixed(1)}&nbsp;&nbsp;
                                    <MyStars rating={summ.review_avg} starSize={17}/>
                                </DataTable.Cell>
                            </DataTable.Row> : null}
                        <DataTable.Row>
                            <DataTable.Cell># players:</DataTable.Cell>
                            <DataTable.Cell>{summ.nplayed}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Win-Loss:</DataTable.Cell>
                            <DataTable.Cell>{othersWon} – {othersLost}</DataTable.Cell>
                        </DataTable.Row>
                        {othersAvgClues > 0 ?
                            <DataTable.Row>
                                <DataTable.Cell>Avg. #clues:</DataTable.Cell>
                                <DataTable.Cell>{othersAvgClues}</DataTable.Cell>
                            </DataTable.Row> : null}
                        <DataTable.Row>
                            <DataTable.Cell>Optimal #clues:</DataTable.Cell>
                            <DataTable.Cell>{meta.min_turns}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>"Par" #clues:</DataTable.Cell>
                            <DataTable.Cell>{1 + meta.min_turns}</DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell>Board ID:</DataTable.Cell>
                            <DataTable.Cell>{summ.pid}</DataTable.Cell>
                        </DataTable.Row>
                    </DataTable>
                </Dialog.Content>
            </Dialog>
        </Portal>
    )
}

