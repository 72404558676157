import {ImageBackground, SafeAreaView, StyleSheet, TouchableOpacity, View} from "react-native";
import {
    BadgeField,
    GAME_BACKGROUND_COLOR,
    KELLY,
    MyStars,
    MyText,
    MyTooltip,
    REVIEW_YELLOW,
    TOUCHABLE_BORDER_COLOR,
    WordChip
} from "./ui";
import React from "react";
import {Feedback, global_state, MetaData, ScoreboardFilter, SPEC_SECTIONS, Turn, useGameState} from "./state";
import {useHookstate} from "@hookstate/core";
import {Appbar, Divider, IconButton, Menu, SegmentedButtons} from 'react-native-paper';
import {StatusBar} from "expo-status-bar";
import {SectionGrid, SectionItem} from "react-native-super-grid";
import {LazyPuzzSummary, PuzzLog, PuzzSummary} from "../models";
import {adminGetAllLogsForPid, loadPuzz, summary_statistics} from "./data";
import {CLUE_COLORS, getMyLog, userIsAdmin} from "./logic";
import Popover from "react-native-popover-view";


const BrowseHeader = ({navigation}) => {
    const config = useGameState()?.config;
    const filter = config?.scoreboardFilter;
    const sorter = config?.scoreboardSorter;
    const sortMenuVisible = useHookstate(false);
    const dismiss = () => sortMenuVisible.set(false);
    const buttons = [{value: 'played', label: 'Played'},
        {value: 'unplayed', label: 'Unplayed'},
        // ...userIsAdmin() ? [{value: 'players', icon: 'account-group'}] : []
    ];
    return (
        <Appbar.Header mode="small" style={{flexShrink: 1, justifyContent: 'space-between'}}>
            <Appbar.Action icon="arrow-left" onPress={navigation.goBack}/>
            <SegmentedButtons
                style={{width: 120 * buttons.length, maxWidth: '80%'}}
                density='small'
                buttons={buttons}
                value={filter.get()}
                onValueChange={value => filter.set(value as ScoreboardFilter)}
            />
            <Menu visible={sortMenuVisible.get()}
                  onDismiss={dismiss}
                  anchor={<IconButton disabled={false} icon='sort' onPress={() => sortMenuVisible.set(true)}/>}>
                <MyText style={{color: 'lightgray'}} size={18}> Sort by:</MyText>
                <Divider/>
                <Menu.Item disabled={false} leadingIcon="star-half-full" title="Rating"
                           trailingIcon={sorter.get() === 'rating' ? 'check' : null}
                           onPress={() => {
                               sorter.set('rating');
                               dismiss();
                           }}/>
                <Menu.Item disabled={false} leadingIcon="percent" title="Win pctg."
                           trailingIcon={sorter.get() === 'win%' ? 'check' : null}
                           onPress={() => {
                               sorter.set('win%');
                               dismiss();
                           }}/>
                {/*<Menu.Item disabled={false} leadingIcon="thumb-down-outline" title="Feedback"*/}
                {/*           trailingIcon={sorter.get() === 'feedback' ? 'check' : null}*/}
                {/*           onPress={() => {*/}
                {/*               sorter.set('feedback');*/}
                {/*               dismiss();*/}
                {/*           }}/>*/}
                <Divider/>
            </Menu>
        </Appbar.Header>
    );
}

const IWON_STYLE = {backgroundColor: '#234C16C0', borderColor: 'lightgray', borderWidth: 0.5};
const ILOST_STYLE = {backgroundColor: '#4C1316C0', borderColor: 'lightgray', borderWidth: 0.5};
const UNPLAYED_STYLE = {backgroundColor: '#444444C0', borderColor: TOUCHABLE_BORDER_COLOR, borderWidth: 0.5};

const StatBadge = ({badgeValue, rightLabel, badgeBackground = KELLY.very_light_blue}) => {
    return (
        <BadgeField labelTextSize={14} labelNarrow={false}
                    badgeValue={badgeValue}
                    rightLabel={rightLabel}
                    badgeSize={20}
                    badgeBackgroundColor={badgeBackground}
                    style={{borderWidth: 0, marginLeft: 0, paddingLeft: 0, alignItems: 'center'}}
        />);
}

export const FeedbackCell = ({puzzLog, summ}: { puzzLog: PuzzLog, summ: PuzzSummary }) => {
    if (!summ) return;
    const metadata = summ?.metadata as unknown as MetaData;
    const feedback = puzzLog?.feedback as unknown as Feedback;
    const turns = puzzLog?.turns as unknown as Turn[];
    const iCommented = feedback?.comments;
    const iThumbsDowned = feedback?.badClue.some(Boolean);
    return (
        <View style={{
            flexDirection: 'row',
            margin: 0,
            justifyContent: 'flex-start',
        }}>
            {!iCommented ? null :
                <MyTooltip title={iCommented}>
                    <IconButton icon='comment-text' size={14} iconColor={KELLY.vivid_orange}
                                style={{
                                    margin: 0,
                                    padding: 0,
                                }}/>
                </MyTooltip>}
            {!iThumbsDowned ? null :
                <Popover from={(<IconButton icon='thumb-down' size={14} iconColor={KELLY.vivid_orange}
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                            }}/>)}>
                    <View style={{backgroundColor: 'black', padding: 5}}>
                        {turns
                            .filter((turn, index) => feedback?.badClue[index])
                            .map((turn, index) => (
                                <View key={index}
                                      style={{
                                          flexGrow: 1,
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          justifyContent: 'space-around',
                                      }}>
                                    <MyText
                                        style={{
                                            width: 160,
                                        }}>{turn.clue.term}</MyText>
                                    <View style={{
                                        flexGrow: 0.6,
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        width: 270
                                    }}>
                                        {turn.clue.intended.map(card_ix =>
                                            <WordChip key={card_ix} disabled={true} borderWidth={0.5}
                                                      borderColor='black'
                                                      innerHeight={25} innerWidth={89}
                                                      colors={[CLUE_COLORS[index]]}
                                                      onPress={() => null}>
                                                <MyText narrow={true} size={12} style={{color: 'white'}}>
                                                    {metadata.terms[card_ix]}
                                                </MyText>
                                            </WordChip>
                                        )}
                                    </View>
                                </View>
                            ))}
                    </View>
                </Popover>}
        </View>

    );
}

const PlayerLine = ({player, puzzLog}: { player: string, puzzLog: PuzzLog }) => {
    const summ = useGameState()?.summaries.get()?.find(summ => summ.pid === puzzLog.of_pid);
    const iLost = puzzLog?.nclues_to_win == 0;

    return (
        <View style={{flexGrow: 1, flexDirection: 'row', alignItems: 'center', paddingVertical: 2}}>
            <View style={{
                flex: 0.6,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}>
                <MyText size={14} style={{textAlign: 'right'}}>
                    {iLost ? player + ' lost.' : player === 'You' ? 'You won in' : player}&nbsp;
                </MyText>
                {iLost ? null :
                    <StatBadge badgeValue={puzzLog.nclues_to_win}
                               rightLabel={player === 'You' ? (puzzLog.nclues_to_win === 1 ? ' clue!' : ' clues.') : null}
                               badgeBackground='lightgray'
                    />}
            </View>
            <View style={{flex: 0.4, flexDirection: 'row', alignItems: 'center'}}>
                {puzzLog.review && puzzLog.review >= 0 ?
                    <MyStars rating={puzzLog.review} starSize={17} color={KELLY.vivid_orange}/> : null}
                <FeedbackCell summ={summ as PuzzSummary} puzzLog={puzzLog}/>
            </View>
        </View>
    );
}

const SummaryCard = ({
                         summ,
                         navigation
                     }: { summ: PuzzSummary, navigation: any }) => {
    const myEmail = global_state?.user?.email?.get();
    const myPuzzLog = getMyLog(summ.pid);
    const iPlayed = myPuzzLog?.nclues_to_win >= 0;
    const iWon = myPuzzLog?.nclues_to_win > 0;
    const iLost = myPuzzLog?.nclues_to_win == 0;
    const othersPlayed = summ.nplayed >= 1;
    const {othersWon, othersLost, othersWinPct, othersAvgClues} = summary_statistics(summ);
    const playIt = () => {
        navigation.navigate('Play');
        loadPuzz(summ.pid).then();
    };
    return (
        <View
            style={[{flexDirection: 'row', width: 360, padding: 5, alignItems: 'center'},
                iWon ? IWON_STYLE : iLost ? ILOST_STYLE : UNPLAYED_STYLE]}>

            <View style={{flexGrow: 1, flexDirection: 'column'}}>

                {/* Row #1: button + card chips */}
                <TouchableOpacity onPress={playIt}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        {iPlayed ?
                            <IconButton icon={'eye'} iconColor='lightgray'
                                        size={27}
                                        onPress={() => {
                                            loadPuzz(summ.pid).then(() => {
                                                navigation.navigate('Play');
                                            })
                                        }}/> :
                            <IconButton icon='play-circle-outline' iconColor='lightgray' size={27} onPress={playIt}/>}
                        {(summ.metadata as unknown as MetaData).terms.slice(0, 3).map((term, index) =>
                            <WordChip key={index} disabled={true} borderWidth={0.5}
                                      borderColor='black'
                                      innerHeight={25} innerWidth={89}
                                      colors={['#AAAAAA88', '#FFFFFF88']}
                                      onPress={() => null}>
                                <MyText narrow={true} size={12} style={{color: 'white'}}>
                                    {term}
                                </MyText>
                            </WordChip>
                        )}
                    </View>
                </TouchableOpacity>

                {/* Row #2: summary of others' performance */}

                {!othersPlayed ? null :
                    <View style={{flexGrow: 1, flexDirection: 'row', padding: 4}}>
                        <View
                            style={{
                                flex: 0.6,
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                flexDirection: 'row',
                            }}>
                            {othersWon > 0 ?
                                <>
                                    {/*<StatBadge badgeValue={othersWinPct + '%'} rightLabel=' won in'/>*/}
                                    <MyText size={14} style={{textAlign: 'right'}}>
                                        {`${othersWon}/${summ.nplayed}`} won in&nbsp;
                                    </MyText>
                                    <StatBadge badgeValue={othersAvgClues} rightLabel=' clues.'/>
                                </> :
                                <MyText size={14} style={{textAlign: 'right'}}>
                                    {`0/${summ.nplayed}`} won.
                                </MyText>}
                        </View>
                        <View style={{
                            flex: 0.4,
                            alignItems: 'center',
                            flexDirection: 'row',
                            // justifyContent: 'space-between'
                        }}>
                            {summ.review_avg && summ.review_avg >= 0 ?
                                <MyStars rating={summ.review_avg} starSize={17}/> : null}
                            <MyText style={{color: REVIEW_YELLOW}}
                                    size={13}>&nbsp;({summ.review_avg.toFixed(1)})</MyText>
                        </View>
                    </View>}

                {/* Row #3: summary of my performance */}
                {!iPlayed ? null : <PlayerLine player='You' puzzLog={myPuzzLog}/>}

                {/* Rows 4+: every other player's performance (admin only) */}
                {!userIsAdmin() ? null :
                    adminGetAllLogsForPid(summ.pid)
                        .filter(puzzLog => puzzLog.email !== myEmail)
                        .sort((a, b) => a.nclues_to_win - b.nclues_to_win)
                        .map(puzzLog =>
                            <PlayerLine key={puzzLog.user_id} player={puzzLog.email?.match(/(.*)@/)[1]}
                                        puzzLog={puzzLog}/>)}

            </View>
        </View>
    )
}

const TAG_SECTIONS = {
    'warmup': {title: 'Warm-ups (minis)'},
    'featured': {title: 'Featured'},
};

const buildSections = (navigation, summaries) => {
    const allSections = [];
    console.log('buildSections:');
    for (const [tag, {title}] of Object.entries(TAG_SECTIONS)) {
        // console.debug(tag, 'filtering', summaries.length);
        const items = summaries.filter((summ) => summ.tags.find(t => t === tag));
        if (items.length) {
            allSections.push({title: title, data: items});
        }
    }
    for (const [spec, {title}] of Object.entries(SPEC_SECTIONS)) {
        const items = summaries.filter((summ) => summ.spec === spec);
        if (items.length) {
            allSections.push({
                title: title,
                data: items,
            });
        }
    }
    // console.log('built ', allSections.length, 'sections');
    return allSections;
}

function wasAlreadyPlayed(pid): boolean {
    const myLog = getMyLog(pid);
    return myLog ? myLog.nclues_to_win >= 0 : false;
}


const BrowseScreen = ({route, navigation}) => {
    const gameState = useGameState();
    console.log('BrowseScreen: lastDataSync=', gameState?.lastDataSync?.get(), '; route=', route);

    const tag = route?.params?.tag;
    const summaries = gameState?.summaries;
    const filterWasPlayed = gameState?.config?.scoreboardFilter.get() === 'played';

    const filteredSummaries = summaries.get().filter(
        summ => filterWasPlayed ? wasAlreadyPlayed(summ.pid) :
            !wasAlreadyPlayed(summ.pid) && !summ.is_unlisted); // don't show unlisted puzzles on 'Unplayed' listing

    const sorter = gameState?.config?.scoreboardSorter?.get();
    const compareFn = (a, b) =>
        (sorter === 'rating' ? b.review_avg - a.review_avg :
            sorter === 'win%' ? a.nclues_to_win_histo[0] / Math.max(1, a.nplayed) - b.nclues_to_win_histo[0] / Math.max(1, b.nplayed) :
                0)
        || b.nplayed - a.nplayed
        || a.pid.localeCompare(b.pid);
    filteredSummaries.sort(compareFn);
    const sections = buildSections(navigation, filteredSummaries);

    return (
        <SafeAreaView style={styles.container}>
            <ImageBackground source={require('../assets/abstract-techno-banner.jpg')}
                             resizeMode='repeat'
                             style={{
                                 margin: 0,
                                 padding: 0,
                                 width: '100%',
                                 // height: '100%',
                                 flexGrow: 1,
                             }}>
                <View style={{
                    flexDirection: 'column', flexGrow: 1,
                }}>
                    <StatusBar hidden={true} animated={true} style="auto"/>
                    <BrowseHeader navigation={navigation}/>

                    <SectionGrid
                        sections={sections as Array<SectionItem<LazyPuzzSummary>>}
                        itemDimension={350}
                        fixed={true}
                        keyExtractor={(summ, index) => `${index} ${summ.pid}`}
                        renderItem={({item}) => (<SummaryCard summ={item} navigation={navigation}/>)}
                        renderSectionHeader={({section: {title}}) => (
                            <MyText size={20} bold
                                    style={{backgroundColor: '#636e72EE', padding: 10}}>{title}</MyText>
                        )}
                        stickySectionHeadersEnabled={true} // works on iOS only
                        itemContainerStyle={{alignItems: 'center'}}
                        contentContainerStyle={{paddingBottom: 200,}} // fixes inability to scroll to bottom on iOS
                    />
                </View>
            </ImageBackground>
        </SafeAreaView>
    );
}

export default BrowseScreen;

const styles = StyleSheet.create({
    container: {
        flexDirection: "column",
        backgroundColor: GAME_BACKGROUND_COLOR,
        width: '100%',
        height: '100%',
    },
});
