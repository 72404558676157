import {
    ImageBackground, Platform, SafeAreaView, StatusBar, useWindowDimensions, View,
} from 'react-native';
import React from "react";
import {useGameState,} from "./state";
import GameHeader from "./GameHeader";
import GameClueBox from "./GameClueBox";
import GameGrid from "./GameGrid";
import {GAME_CLUEBOX_TARGET_WIDTH, GAME_MAX_HEIGHT, GAME_MAX_WIDTH, useGameDimensions} from "./ui";


const GameScreen = ({navigation}) => {
    const gameState = useGameState();
    const {usableHeight, usableWidth} = useGameDimensions();
    const usableGridHeight = Math.floor(usableHeight * 0.55);
    const usableClueHeight = Math.floor(usableHeight - 20 - usableGridHeight)
    const usableClueWidth = Math.min(usableWidth * 0.96, Math.max(GAME_CLUEBOX_TARGET_WIDTH, usableWidth * 0.6));
    const puzz = gameState?.puzz;
    const status = puzz?.status;
    if (!status) return;
    // console.log('Rendering GameScreen', gameState.get({stealth: true}));
    return (
        <SafeAreaView style={{
            // backgroundColor: '#eed8b7',
            height: '100%',
            width: '100%',
            flexDirection: 'row', // center the child view horizontally on the screen
            justifyContent: 'center',
            paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
        }}>
            <ImageBackground source={require('../assets/abstract-technological-background.jpg')}
                             style={{
                                 margin: 0,
                                 padding: 0,
                                 flexGrow: 1,
                                 flexDirection: 'column',
                                 alignItems: 'center',
                             }}>
                <View style={{
                    flexDirection: 'column', flexGrow: 1,
                    maxWidth: usableWidth, maxHeight: usableHeight
                }}>
                    <View style={{height: usableClueHeight}}>
                        <GameClueBox maxWidth={usableClueWidth} maxHeight={usableClueHeight}
                                     navigation={navigation} showHelpText={true}/>
                    </View>
                    <View>
                        <GameGrid usableHeight={usableGridHeight} usableWidth={usableWidth}/>
                    </View>
                </View>
            </ImageBackground>
        </SafeAreaView>
    )
}


export default GameScreen;

