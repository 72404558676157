import {KELLY, MyText, MyBadge, TOUCHABLE_BORDER_COLOR, WordChip} from "./ui";
import {cardChar, GameState, useGameState} from "./state";
import {View} from "react-native";
import {State} from "@hookstate/core";
import React from "react";
import {CLUE_COLORS, handleGuess} from "./logic";
import {logGame} from "./data";

const Card = ({
                  index,
                  card,
                  can_guess,
                  game_over,
                  innerHeight,
                  innerWidth,
                  cardBorder = 3
              }) => {
    const gameState: State<GameState> = useGameState();
    const status = gameState?.puzz?.status;
    const disabled = !can_guess || card.is_revealed;
    const showWasMissed = game_over && card.is_target && !card.is_revealed;
    const isOpen = card.is_revealed || game_over;
    const showWasClueTarget = status?.show_targeted_cards.get().includes(index);
    const revealedColors =
        card.is_bonus ? ['black', '#444'] :
            !card.is_target ? ['black'] :
                card.turns_clued?.length == 0 ? [KELLY.very_light_blue] :
                    card.turns_clued?.map((turn) => CLUE_COLORS[turn]);
    return (
        <WordChip disabled={disabled} borderWidth={cardBorder}
                  borderColor={showWasClueTarget ? 'white' :
                      (showWasMissed || !disabled) ? TOUCHABLE_BORDER_COLOR : 'black'}
                  innerHeight={innerHeight} innerWidth={innerWidth}
                  colors={isOpen ? revealedColors : ['lightgray', 'ghostwhite']}
                  onPress={() => {
                      handleGuess(index);
                      if (gameState.puzz.status.game_over.get() !== 'false') {
                          logGame().then(puzzLog => console.log('Logged', puzzLog));
                      }
                  }}>
            <MyText narrow={true}
                    size={17}
                    bold={showWasMissed}
                    style={{
                        // paddingHorizontal: 0, // save room for the text
                        color: isOpen ? 'white' : 'black',
                        opacity: 1, //card.is_revealed && !game_over ? 0.5 : 1,
                    }}>
                {card.word}
            </MyText>
            <View style={{position: 'absolute', right: 1, top: -1}}>
                <MyText size={showWasClueTarget ? 20 : 15} style={{color: 'white'}}>
                    {cardChar(card, game_over)}
                </MyText>
            </View>
        </WordChip>
    )
}

const GameGrid = ({usableWidth, usableHeight}) => {
    const gameState: State<GameState> = useGameState();
    const cards = gameState?.puzz?.cards?.get();
    if (!cards) return;
    const status = gameState?.puzz?.status;
    const can_guess = status?.can_guess.get();

    const portrait_mode = usableWidth < 1.5 * usableHeight
    let max_ncols, nrows, first_row_ncols;
    if (portrait_mode) {
        max_ncols = first_row_ncols = 3;
        nrows = Math.ceil(cards.length / max_ncols);
    } else {
        // landscape mode
        if (cards.length <= 18) {
            max_ncols = 4;
            first_row_ncols = 2;
            nrows = Math.ceil(cards.length / max_ncols) + 1;
        } else {
            max_ncols = 5;
            first_row_ncols = 3;
            nrows = Math.ceil(cards.length / max_ncols);
        }
    }

    const horizMargin = 5, vertMargin = 5, cardBorder = 3;
    const widthPerItem = Math.max(110, Math.floor(usableWidth / max_ncols - horizMargin - 2 * cardBorder));
    const heightPerItem = Math.max(20, Math.floor(usableHeight / nrows - vertMargin - 2 * cardBorder));

    // console.log('Want to use w=', usableWidth, 'h=', usableHeight, '.');
    // console.log('Got height', heightPerItem, 'over', nrows, 'rows.');
    // console.log('Got width', widthPerItem, 'over', max_ncols, 'cols.');

    const gridRows = [];
    let cardsPlaced = 0;
    while (cardsPlaced < cards.length) {
        const thisRow = [];
        for (let i = 0; i < (gridRows.length == 0 ? first_row_ncols : max_ncols) && cardsPlaced < cards.length; i++) {
            thisRow.push(<Card key={cardsPlaced}
                               innerHeight={heightPerItem} // not including border
                               innerWidth={widthPerItem} // not including border
                               cardBorder={cardBorder}
                               index={cardsPlaced} card={cards[cardsPlaced]}
                               can_guess={can_guess} game_over={status?.game_over.get() !== 'false'}/>);
            cardsPlaced++;
        }
        gridRows.push(<View key={cardsPlaced} style={{
            flexDirection: 'row',
            flexGrow: 0,
            flexWrap: 'nowrap',
            height: heightPerItem + vertMargin + 2 * cardBorder,
        }}>{thisRow}</View>);
    }
    return (
        <View style={{
            flexDirection: 'column',
            alignItems: 'center',
            flexGrow: 0,
            marginTop: 10,
            height: usableHeight,
        }}>
            {gridRows}
        </View>
    );
}

export default GameGrid;