import {
    CheckboxIcon,
    KELLY,
    MyButton,
    MyText,
    REVIEW_YELLOW,
    TOUCHABLE_BORDER_COLOR,
    MyColumn,
    GAME_BACKGROUND_COLOR, MyRow, MyBadge, GAME_LIGHT_BACKGROUND_COLOR
} from "./ui";
import {ScrollView, StyleProp, StyleSheet, TouchableHighlight, TouchableOpacity, View, ViewStyle} from "react-native";
import {useGameState} from "./state";
import {Badge, IconButton} from "react-native-paper";
import React, {useRef} from "react";
import {chooseClueOption, queryNextClue, CLUE_COLORS} from "./logic";
import {GameOver} from "./GameOver";
import {logGame} from "./data";
import {useHookstate} from "@hookstate/core";
import {InfoModal} from "./GameInfo";
import GameHeader from "./GameHeader";
import {LinearGradient} from "expo-linear-gradient";


const TurnButton = ({
                        icon,
                        label,
                        disabled,
                        style
                    }: { icon: string, label: string, disabled: boolean, style?: StyleProp<ViewStyle> }) => {
    const state = useGameState();
    return (
        <View style={{
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'flex-start',
            paddingLeft: 15,
        }}>
            <MyButton disabled={disabled}
                      loading={state?.query_in_progress?.get() === "clue"}
                      icon={icon}
                      style={style}
                      fontSize={16}
                      narrow={true}
                      textColor={KELLY.very_light_blue}
                      onPress={queryNextClue}
                      label={label}/>
        </View>
    );
}


const TurnChooseButton = ({icon, label, clueNum, style}:
                              { icon: string, label: string, clueNum: number, style?: StyleProp<ViewStyle> }) => {
    return (
        <View style={{display: 'flex', flexDirection: 'row'}}>
            <MyButton icon={icon}
                      narrow={true}
                      style={[{marginHorizontal: 1}, style]}
                      onPress={() => chooseClueOption(clueNum)}
                      label={label}/>
        </View>
    );
}

const TurnSegButtons = ({icon}) => {
    const status = useGameState()?.puzz?.status;
    const options = status?.pending_clue_options;
    const bestCount = options[0].count.get();
    // const bestScore = options[0].score.get();
    return (
        <View style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 5}}>
            <TurnButton icon={icon} disabled={false} label='Clue for...'
                        style={{borderTopRightRadius: 0, borderBottomRightRadius: 0, marginRight: -10}}/>
            {options.map((clue, index) =>
                <TurnChooseButton key={index}
                                  icon={clue.count.get() === bestCount ? 'star' : undefined}
                    // bestScore - clue.score.get() <= 0.4 ? 'star-half' : undefined}
                                  label={clue.count.get().toString()}
                                  clueNum={index}
                                  style={index == options.length - 1 ? {
                                          borderTopLeftRadius: 0,
                                          borderBottomLeftRadius: 0
                                      } :
                                      {borderRadius: 0}}/>
            )}
        </View>
    );
}

const TurnBadge = ({number, size = 18, backgroundColor, color, style = null}) => {
    const parLine = number === useGameState()?.puzz?.spec.par.get();
    const style1 = {
        marginRight: 0, // leave as much room as possible for the clue word
        alignSelf: 'center',
        borderWidth: parLine ? 0.5 : 0,
        borderColor: 'white',
        ...style
    };
    return (<>
        <MyBadge size={size} style={style1} score={number} backgroundColor={backgroundColor} color={color}/>
    </>);
}

// clue & checkboxes are given as children; here we decorate with doodads like thumbs-downs.
const TurnLine = ({index}) => {
    const puzz = useGameState()?.puzz;
    const status = puzz?.status;
    const cards = puzz?.cards;
    const game_over = status?.game_over.get();
    const turns = puzz?.turns;

    const chosenClue = turns[index].clue.get({noproxy: true});
    // console.debug('chosenClue=', chosenClue);
    const intendedCards = chosenClue.intended;
    const showIntendedCardsOnTouch = game_over === 'false' ?
        intendedCards.filter(cardIx => cards[cardIx].is_revealed.get()) : intendedCards;
    const isActive = game_over === 'false' && intendedCards.some(ix => !cards[ix].is_revealed.get());

    return (
        <View style={styles.turnLine}
              onLayout={event => {
                  const {layout} = event.nativeEvent;
                  console.log('index', index, 'y', layout.y);
              }}
        >
            <TurnBadge number={1 + index}
                       backgroundColor={game_over === 'lost-turns' ? TOUCHABLE_BORDER_COLOR : 'black'}
                       color={game_over === 'lost-turns' ? 'black' : 'white'}
            />
            <View
                style={{
                    flex: 0.58,
                    alignItems: 'center',
                    flexDirection: 'row',
                    marginRight: 7,
                    justifyContent: 'flex-end'
                }}>
                <TouchableHighlight
                    activeOpacity={1}
                    underlayColor={CLUE_COLORS[index]}
                    onPressIn={() => status.show_targeted_cards.set(showIntendedCardsOnTouch)}
                    onLongPress={() => status.show_targeted_cards.set(showIntendedCardsOnTouch)}
                    onPressOut={() => status.show_targeted_cards.set([])}>
                    <MyText bold={isActive} narrow={false} size={20}>
                        {chosenClue.term}
                    </MyText>
                </TouchableHighlight>
            </View>
            <View style={{
                flex: 0.42,
                alignSelf: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                flexWrap: 'wrap'
            }}>
                {intendedCards.map((card_ix, map_ix) =>
                    <TouchableOpacity key={map_ix}
                                      activeOpacity={0.85}
                                      onPressIn={() => status.show_targeted_cards.set(showIntendedCardsOnTouch.filter(i => i === card_ix))}
                                      onLongPress={() => status.show_targeted_cards.set(showIntendedCardsOnTouch.filter(i => i === card_ix))}
                                      onPressOut={() => status.show_targeted_cards.set([])}>
                        <CheckboxIcon checked={cards[card_ix].is_revealed.get()}
                                      color={CLUE_COLORS[index]}/>
                    </TouchableOpacity>)}
            </View>

            {status?.show_clue_feedback_ui?.get() && index >= 0 &&
                <View style={{position: 'absolute', right: -10}}>
                    <IconButton icon={status.feedback.badClue[index].get() ? 'thumb-down' : 'thumb-down-outline'}
                                onPress={() => {
                                    status.feedback.badClue[index].set(val => !val);
                                    logGame().then();
                                }}
                                iconColor={REVIEW_YELLOW}
                                size={20} style={{padding: 0, margin: 0}}/>
                </View>
            }
        </View>
    );
}

const NextClueLine = ({i, maxTurns, showHelpText}) => {
    const puzz = useGameState()?.puzz;
    const spec = puzz?.spec;
    const status = puzz?.status;

    const buttonDisabled = !status?.can_request_clue.get();
    const label = (i + 1 < maxTurns) ? `Clue #${i + 1}` : `Final clue`;
    return (
        <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: !showHelpText ? 'flex-start' : 'center', // buttonDisabled ? 'center' : 'flex-end',
            marginTop: 5,
        }}>
            {showHelpText &&
                <MyText narrow={true} style={{color: KELLY.very_light_blue}} numLines={2}>
                    {!status.can_guess.get() ? '' :
                        status.can_request_clue.get() ? 'Keep guessing, or' :
                            status.num_clued_but_unrevealed.get() === 1 ? 'Guess the matching word...' :
                                'Guess matching words...'}
                </MyText>}
            {(!showHelpText || !buttonDisabled) &&
                <TurnButton
                    disabled={buttonDisabled}
                    icon={'forward'}
                    label={label + (spec.count_choice.get() === 'CHOICE' ? '...' : '')}
                />}
        </View>
    );
}

const GameClueBox = ({navigation, maxHeight, maxWidth, showHelpText}) => {
    const scrollRef = useRef(null)
    const gameState = useGameState();
    const infoVisible = useHookstate(false);
    const puzz = gameState?.puzz;
    const spec = puzz?.spec;
    const status = puzz?.status;
    const game_over = status?.game_over.get();
    const turns = puzz?.turns;
    if (!turns || !spec) return;
    const maxTurns = spec?.max_turns.get();
    const turnLines = [];
    // const num_not_yet_clued = puzz.bixs.length - status.tot_blue_guessed.get() - status.num_clued_but_unrevealed.get();

    // Add 1 line for each clue so far.
    let i: number;
    for (i = 0; i < turns.length; i++) {
        turnLines.push(
            <TurnLine key={i} index={i}/>
        );
    }

    // Add 1 line immediately below the clues with instructions + next clue button.
    if (game_over === 'false' && i <= maxTurns) {
        turnLines.push(
            <View key={i} style={styles.turnLine}>
                {/*<TurnBadge number={1 + i} backgroundColor='black' color='white'/>*/}
                <View style={{
                    position: 'relative',
                    justifyContent: 'center',
                    flexGrow: 1,
                    alignItems: 'center',
                }}>
                    {status?.pending_clue_options.length > 1 ?
                        <TurnSegButtons icon='forward'/>
                        :
                        <NextClueLine i={i} maxTurns={maxTurns} showHelpText={showHelpText}/>}
                </View>
                <View style={{width: 10}}/>
            </View>
        );
        i++;
    }

    // if (game_over === 'false') {
    //     for (; i < spec.max_turns.get(); i++) {
    //         turnLines.push(
    //             <MyRow key={i}
    //                 // style={{flex: 1, justifyContent: 'space-between', alignItems: 'center', padding: 5}}>
    //                    style={styles.turnLine}>
    //                 <TurnBadge number={1 + i} backgroundColor='black' color='white'/>
    //                 <View style={{width: 10, height: 20}}/></MyRow>)
    //     }
    // }

    return (
        <MyColumn style={{
            flexGrow: 1,
            flexShrink: 1,
            minHeight: 100,
            maxHeight: maxHeight,
            width: '100%',
            alignSelf: 'center'
        }}>

            <InfoModal isVisible={infoVisible}/>
            <MyRow style={{justifyContent: 'space-between', width: '100%'}}>
                {/*back button*/}
                <IconButton iconColor={'lightgray'} icon="arrow-left" onPress={navigation.goBack}
                            style={{flexShrink: 0.05, margin: 0}}/>
                {spec.header_style.get() === 'badges' ? null :
                    <IconButton disabled={false} iconColor={'lightgray'} style={{flexShrink: 0.05, margin: 0}}
                                icon="information-outline"
                        //  icon={"numeric-" + (spec.par.get() >= 9 ? "9-plus" : spec.par.get()) + "-circle-outline"}
                                onPress={() => infoVisible.set(true)}
                    />}
            </MyRow>
            <MyColumn>
                <View style={{
                    top: -38,
                    zIndex: 20,
                    position: 'absolute',
                    alignSelf: 'center',
                    paddingTop: 2,
                    marginTop: 4,
                }}>
                    <LinearGradient start={[0, 0]}
                                    end={[1, 1]}
                                    colors={[GAME_LIGHT_BACKGROUND_COLOR, GAME_BACKGROUND_COLOR]}
                                    style={{
                                        zIndex: 10, margin: 0, borderRadius: 9,
                                    }}>
                        <View style={{
                            borderWidth: 3,
                            borderColor: 'white',
                            borderRadius: 9,
                            // backgroundColor: GAME_LIGHT_BACKGROUND_COLOR,
                            paddingHorizontal: 10,
                            height: 45,
                        }}>
                            <GameHeader maxHeaderWidth={maxWidth} infoVisible={infoVisible}/>
                        </View>
                    </LinearGradient>
                </View>

                <ScrollView ref={scrollRef}
                            style={{
                                height: maxHeight - 45,
                                width: maxWidth,
                                alignSelf: 'center',
                                borderWidth: 3,
                                borderRadius: 15,
                                borderColor: 'white',
                                marginHorizontal: 5,
                                marginBottom: 5,
                                paddingTop: 5,
                            }}
                            onContentSizeChange={() => scrollRef.current.scrollToEnd()}>
                    <View style={{
                        flexGrow: 1,
                        flexDirection: 'column',
                        paddingTop: 20,
                        paddingBottom: 15,
                        paddingRight: 10,
                        minWidth: 150,
                    }}>
                        {turnLines}
                    </View>
                    <View>
                        <GameOver navigation={navigation}/>
                    </View>
                </ScrollView>

            </MyColumn>
        </MyColumn>
    );
}

export default GameClueBox;


const styles = StyleSheet.create({
    turnLine: {
        paddingHorizontal: 5,
        paddingVertical: 3,
        flexDirection: 'row',
        minHeight: 31,
        alignItems: 'center', // fixes thumb-down vertical position
    },
});
