import {TouchableOpacity, View} from "react-native";
import {IconButton} from "react-native-paper";
import {
    BadgeField,
    CheckboxesField, GAME_MAX_WIDTH,
    KELLY, MyText,
    TOUCHABLE_BORDER_COLOR
} from "./ui";
import React from "react";
import {useGameState} from "./state";
import {useHookstate} from "@hookstate/core";
import {InfoModal} from "./GameInfo";


const GameHeader = ({maxHeaderWidth, infoVisible}) => {
    const gameState = useGameState();
    const puzz = gameState?.puzz;
    const status = puzz?.status;
    const spec = puzz?.spec;
    if (!status) return;

    return (
        <View style={{
            flexGrow: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
            maxWidth: maxHeaderWidth - 70, minWidth: maxHeaderWidth - 130,
        }}>
            {spec?.par.get() &&
                <>
                    <TouchableOpacity onPress={() => infoVisible.set(true)}>
                        <BadgeField leftLabel='Par  '
                                    badgeValue={spec?.par.get()}
                                    badgeColor='lightgray'
                                    badgeBackgroundColor='black'
                                    badgeBorderWidth={0.5}
                                    badgeSize={25}
                                    style={{minHeight: 31, paddingHorizontal: 5, borderWidth: 0}}
                        />
                    </TouchableOpacity>
                    <View style={{flexGrow: 1}}/>
                </>

            }

            {spec.header_style.get() === 'badges' ?

                <BadgeField badgeValue={puzz.bixs.length - status.tot_blue_guessed.get()}
                            leftLabel={'To find  '} rightLabel={null}
                            badgeBackgroundColor={KELLY.very_light_blue}
                            style={{minHeight: 31, paddingHorizontal: 5, borderWidth: 0}}
                />
                :
                <CheckboxesField leftLabel='✓' numBoxes={puzz.bixs.length} size={16}
                                 style={{minHeight: 31, paddingHorizontal: 5, borderWidth: 0}}
                                 numChecked={status.tot_blue_guessed.get()} color={KELLY.very_light_blue}/>
            }
            <View style={{flexGrow: 1}}/>
            <CheckboxesField leftLabel=' ✘' xbox={true} numBoxes={spec.max_mistakes.get()} size={16}
                             numChecked={status.tot_mistakes.get()}
                             color={status.game_over.get() === 'lost-mistakes' ? TOUCHABLE_BORDER_COLOR : 'lightgray'}
                             style={{borderWidth: 0, minHeight: 31, paddingHorizontal: 5}}
            />

        </View>

    )
}

export default GameHeader;