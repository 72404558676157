// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { PuzzSummary, PuzzLog } = initSchema(schema);

export {
  PuzzSummary,
  PuzzLog
};